import classNames from 'classnames';
import { useLanguage } from 'contexts/LanguageContext';
import { FC } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { FlagIcon } from 'ui/FlagIcon';
import { mapLanguageToCountryCode } from 'utils/language/mapLanguageToCountryCode';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { ReactComponent as AltArrowDown } from 'assets/icons/line/AltArrowDown.svg';
import styles from './NavbarLanguageDropdownButton.module.scss';

interface NavbarLanguageDropdownButtonProps {
  isActive?: boolean;
}

export const NavbarLanguageDropdownButton: FC<
  NavbarLanguageDropdownButtonProps
> = ({ isActive }) => {
  const { currentLanguage } = useLanguage();

  const isMobile =
    getMinimalUnderBreakpoint() === Breakpoints.Md ||
    getMinimalUnderBreakpoint() === Breakpoints.Sm;

  return (
    <Button
      className={styles.button}
      theme={ButtonTheme.Grey}
      size={isMobile ? ButtonSize.Medium : ButtonSize.Small}
      textWrapperClassName={styles.textWrapper}
      iconConfig={{
        iconComponent: AltArrowDown,
        containerClassName: classNames(
          styles.icon,
          isActive && styles.activeIcon
        ),
      }}
    >
      <div className={styles.buttonContent}>
        <div className={styles.innerButtonContent}>
          <FlagIcon code={mapLanguageToCountryCode[currentLanguage]} />
          <span className={styles.innerButtonText}>
            {mapLanguageToDisplayName(currentLanguage)}
          </span>
        </div>
      </div>
    </Button>
  );
};
