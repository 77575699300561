import { Currency } from 'types';

const currencies = new Set(Object.values(Currency));

export const checkIsCurrency = (value?: unknown): value is Currency => {
  if (!value || typeof value !== 'string') {
    return false;
  }

  return currencies.has(value as Currency);
};
