import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { SearchInput } from 'ui/SearchInput';
import { Currency } from 'types';
import { FC } from 'react';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { PartnerRevShareStatisticTableSearchValues } from './types';
import { FieldName } from './const';
import { PartnerRevShareStatisticCurrencySetting } from '../PartnerRevShareStatisticCurrencySetting';
import styles from './PartnerRevShareStatisticTableSearch.module.scss';
import { PartnerRevShareStatisticTableFilters } from '../PartnerRevShareStatisticTableFilters';

interface PartnerRevShareStatisticTableSearchProps {
  updateCurrency: (currency: Currency) => void;
  initialValues?: PartnerRevShareStatisticTableSearchValues;
  disabled?: boolean;
}

export const PartnerRevShareStatisticTableSearch: FC<
  PartnerRevShareStatisticTableSearchProps
> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Currency]: Currency.USD,
    [FieldName.Filters]: undefined,
  },
  disabled = false,
  updateCurrency,
}) => {
  const { t } = useTranslation('statistic');

  const { onSearchChange } = useSearchHandler();
  const { onFiltersChange, hasFilters } = useFiltersHandlers();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search, filters }) => {
        if (filters) {
          onFiltersChange<PartnerStatisticFilterType>(filters);
        }

        onSearchChange(search);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              'partner.action.filters.button.filtersAndSettings'
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            name={FieldName.Search}
            placeholder={t('partner.action.search.label.search')}
            disabled={disabled || hasFilters}
            settings={
              <div>
                <PartnerRevShareStatisticCurrencySetting
                  currentCurrency={values.currency}
                  onChange={(currency: Currency) => {
                    setFieldValue(FieldName.Currency, currency);
                    updateCurrency(currency);
                  }}
                />
              </div>
            }
            filters={
              <div>
                <PartnerRevShareStatisticTableFilters
                  onChange={handleSubmit}
                  onClear={() => {
                    setFieldValue(FieldName.Filters, {});
                    handleSubmit();
                  }}
                />
              </div>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
