import { FC } from 'react';
import { useLanguage } from 'contexts/LanguageContext';
import { getSortedCurrencies } from 'utils/currency/getSortedCurrencies';
import { Currency } from 'types';
import { blurActiveElement } from 'helpers/blurActiveElement';
import { Skeleton } from 'ui/Skeleton';
import { CurrencyDropdown } from 'components/CurrencyDropdown';
import { Error } from 'ui/Error';
import {
  getDropdownStateByCurrency,
  getListItemsByCurrencies,
} from './helpers';
import { useGetAvailableCurrenciesQueryForStatistic } from '../../../../../../../../hooks/useGetAvailableCurrenciesQueryForStatistic';

interface PartnerRevShareStatisticCurrencySettingProps {
  currentCurrency: Currency;
  onChange: (currency: Currency) => void;
}

export const PartnerRevShareStatisticCurrencySetting: FC<
  PartnerRevShareStatisticCurrencySettingProps
> = ({ currentCurrency, onChange }) => {
  const { currentLanguage } = useLanguage();

  const { availableCurrenciesForStatistic, loading, error } =
    useGetAvailableCurrenciesQueryForStatistic();

  const sortedCurrencies = getSortedCurrencies({
    currencies: availableCurrenciesForStatistic,
    language: currentLanguage,
    selectedCurrency: currentCurrency,
  });

  const dropdownButtonState =
    currentCurrency && getDropdownStateByCurrency(currentCurrency);

  const disabled =
    loading ||
    !sortedCurrencies?.length ||
    !currentCurrency ||
    sortedCurrencies.length === 1;

  const handleItemClick = (currency?: Currency) => {
    if (currency && currency !== currentCurrency) {
      onChange(currency);
    }

    blurActiveElement();
  };

  if (loading || !dropdownButtonState) {
    return <Skeleton width={121} height={32} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <CurrencyDropdown
      value={dropdownButtonState.value}
      disabled={disabled}
      icon={dropdownButtonState.icon}
      items={getListItemsByCurrencies({
        onClick: handleItemClick,
        availableCurrencies: sortedCurrencies,
        language: currentLanguage,
      })}
    />
  );
};
