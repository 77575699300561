import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { NonNullableDateRange } from 'types';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useSearchParams } from 'react-router-dom';
import { useGetRevShareStatisticDetailedQuery } from './hooks/useGetRevShareStatisticDetailedQuery';
import { useRevShareStatisticDetailedColumns } from './hooks/useRevShareStatisticDetailedColumns';
import { extractDetailedStatisticItems } from './helpers';
import { useGenerateRevShareStatisticDetailedSummaryRow } from './hooks/useGenerateRevShareStatisticDetailedSummaryRow';
import { RevShareReferralLinkStatisticItemData } from './query/fragments/generated/RevShareReferralLinkStatisticItemData';
import { RevShareStatisticDetailedTableSearch } from './components/RevShareStatisticDetailedTableSearch';

interface RevShareStatisticDetailedTableProps {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const RevShareStatisticDetailedTable: FC<
  RevShareStatisticDetailedTableProps
> = ({ revShareMediaItemId, nonNullableDateRange }) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const { t } = useTranslation('statistic');
  const translationUserType = useTranslationUserType();

  const {
    revShareStatisticDetailed,
    pageInfo,
    fetchMore,
    isSliced,
    loading: itemsQueryLoading,
    error: itemsQueryError,
  } = useGetRevShareStatisticDetailedQuery({
    revShareMediaItemId,
    nonNullableDateRange,
    searchQuery,
  });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateRevShareStatisticDetailedSummaryRow({
    revShareMediaItemId,
    nonNullableDateRange,
    searchQuery,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useRevShareStatisticDetailedColumns();

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const tableData: Array<RevShareReferralLinkStatisticItemData> | undefined =
    extractDetailedStatisticItems(
      revShareStatisticDetailed?.revShareStatistic.statisticItems
    ) ?? [];

  return (
    <Table
      columns={columns}
      data={tableData}
      hasSearchParams={!!searchQuery}
      emptyText={t(`${translationUserType}.content.placeholder.noData`)}
      summaryRow={summaryRowData}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
      error={error}
      emptySearchStateTitle={t(
        `${translationUserType}.content.placeholder.referrals.referralsNoDataHeader`
      )}
      emptySearchStateDescription={t(
        `${translationUserType}.content.placeholder.referrals.referralsNoDataSubheader`
      )}
      errorStateTitle={t(
        'placeholder.loadingError.loadingErrorHeaderReferrals'
      )}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheaderReferrals'
      )}
      searchComponent={
        <RevShareStatisticDetailedTableSearch
          initialValues={{ search: searchQuery }}
        />
      }
    />
  );
};
