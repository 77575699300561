import { FC } from 'react';
import { UserTypeName, NonNullableDateRange, Currency } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { CompanyRevShareStatisticTable } from './components/CompanyRevShareStatisticTable';
import { PartnerRevShareStatisticTable } from './components/PartnerRevShareStatisticTable';

interface RevShareStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
  updateCurrency: (currency: Currency) => void;
  currency: Currency;
}

export const RevShareStatisticTable: FC<RevShareStatisticTableProps> = ({
  nonNullableDateRange,
  updateCurrency,
  currency,
}) => {
  const { t } = useTranslation('common');

  const { userTypeName } = useGetUserTypeNameQuery({
    onError: () => {
      toast.error(t('errors.commonError'));
    },
  });

  return userTypeName === UserTypeName.Company ? (
    <CompanyRevShareStatisticTable
      nonNullableDateRange={nonNullableDateRange}
      currency={currency}
      updateCurrency={updateCurrency}
    />
  ) : (
    <PartnerRevShareStatisticTable
      nonNullableDateRange={nonNullableDateRange}
      currency={currency}
      updateCurrency={updateCurrency}
    />
  );
};
