import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Accordion.module.scss';

interface AccordionProps {
  isOpen: boolean;
  children: ReactNode;
}

export const Accordion: FC<AccordionProps> = ({ children, isOpen = false }) => (
  <div
    data-testid="accordion"
    hidden={!isOpen}
    className={classNames(styles.container, {
      [styles.expanded]: isOpen,
    })}
  >
    <div className={styles.content}>{children}</div>
  </div>
);
