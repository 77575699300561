import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  AccessorKeyColumnDef,
} from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import { CompanyCpaStatisticItemData } from '../queries/fragments/generated/CompanyCpaStatisticItemData';
import { getStatisticCpaItemOwnerToShow } from '../helpers';
import { COLUMNS_SIZES } from '../../../const';
import { useCopmanyCpaStatisticItemsColumnsManager } from './useCopmanyCpaStatisticItemsColumnsManager';

interface UseCompanyCpaStatisticItemsColumnsReturnValue {
  visibleColumns: Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>;
  updateVisibleColumns: (newColumns: Array<string>) => void;
  optionalColumns: Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>;
}

export const useCompanyCpaStatisticItemsColumns =
  (): UseCompanyCpaStatisticItemsColumnsReturnValue => {
    const { t } = useTranslation(['common', 'statistic']);
    const columnHelper = createColumnHelper<CompanyCpaStatisticItemData>();

    /*
     *  We need to use any as generic argument while bug won't be fixed
     *  Issue https://github.com/TanStack/table/issues/4382
     */
    const columns: Array<
      AccessorKeyColumnDef<CompanyCpaStatisticItemData, any>
    > = [
      columnHelper.accessor('id', {
        header: t(`statistic:company.content.dataTable.cpa.mediaElement`),
        cell: ({ row }) => (
          <MultilineCellWithIcon
            title={row.original.name}
            subTitle={
              <CopyLink
                tooltipText={t('linkCopied')}
                to={row.original.link}
                target="_blank"
              >
                {row.original.link}
              </CopyLink>
            }
          />
        ),
        size: COLUMNS_SIZES.mediaNameSize,
        enablePinning: true,
      }),
      columnHelper.accessor('owner', {
        header: t('statistic:company.content.dataTable.cpa.owner'),
        cell: ({ row }) => getStatisticCpaItemOwnerToShow(row.original.owner),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.hits', {
        header: t('statistic:company.content.dataTable.cpa.hits'),
        cell: ({ renderValue }) => renderValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.hosts', {
        header: t('statistic:company.content.dataTable.cpa.hosts'),
        cell: ({ renderValue }) => renderValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.registrations', {
        header: t('statistic:company.content.dataTable.cpa.registrations'),
        cell: ({ renderValue }) => renderValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.firstDeposits', {
        header: t('statistic:company.content.dataTable.cpa.firstDeposits'),
        cell: ({ renderValue }) => renderValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.depositsAll', {
        header: t('statistic:company.content.dataTable.cpa.depositsAll'),
        cell: ({ getValue }) => getMoneyAmount(getValue()),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.hold', {
        header: t('statistic:company.content.dataTable.cpa.hold'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.qualified', {
        header: t('statistic:company.content.dataTable.cpa.qualified'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.approved', {
        header: t('statistic:company.content.dataTable.cpa.approved'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.paid', {
        header: t('statistic:company.content.dataTable.cpa.paid'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.rejected', {
        header: t('statistic:company.content.dataTable.cpa.rejected'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.reward', {
        header: t('statistic:company.content.dataTable.cpa.reward'),
        cell: ({ getValue }) => getMoneyAmount(getValue()),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.averageDeposit', {
        header: t('statistic:company.content.dataTable.cpa.averageDeposit'),
        cell: ({ getValue }) => getMoneyAmount(getValue()),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.redepositsAll', {
        header: t('statistic:company.content.dataTable.cpa.redepositsAll'),
        cell: ({ getValue }) => getMoneyAmount(getValue()),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
        header: t('statistic:company.content.dataTable.cpa.hosts2reg'),
        cell: ({ getValue }) => `${getValue()}%`,
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
        header: t('statistic:company.content.dataTable.cpa.reg2dep'),
        cell: ({ getValue }) => `${getValue()}%`,
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.numberOfRedeposits', {
        header: t('statistic:company.content.dataTable.cpa.numberOfRedeposits'),
        cell: ({ getValue }) => getValue(),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.withdrawalsAll', {
        header: t('statistic:company.content.dataTable.cpa.withdrawalsAll'),
        cell: ({ getValue }) => getMoneyAmount(getValue()),
        minSize: COLUMNS_SIZES.default,
      }),
    ];

    const { optionalColumns, visibleColumns, updateVisibleColumns } =
      useCopmanyCpaStatisticItemsColumnsManager({
        columns,
      });

    return {
      optionalColumns,
      visibleColumns,
      updateVisibleColumns,
    };
  };
