import { ReactNode } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { ApolloError } from '@apollo/client';
import { EmptyDataBlockWithContainer } from 'components/EmptyDataBlockWithContainer';
import { TableBody } from './components/TableBody';
import { TableHeader } from './components/TableHeader';
import { LoadMoreStatus } from './components/LoadMoreStatus';
import styles from './Table.module.scss';
import { SearchBlock } from './components/SearchBlock';
import { ExtendedTData } from './types';

interface TableProps<TData> {
  columns: Array<ColumnDef<TData>>;
  data: Array<TData>;
  isLoading: boolean;
  hasMoreData: boolean;
  fetchMore: () => void;
  emptyText: string;
  error?: ApolloError;
  summaryRow?: Array<ReactNode>;
  onRowClick?: (arg: TData) => void;
  searchComponent?: ReactNode;
  hasSearchParams?: boolean;
  emptySearchStateTitle?: string;
  emptySearchStateDescription?: string;
  errorStateTitle?: string;
  errorStateDescription?: string;
}

export const Table = <TData,>({
  columns,
  data,
  isLoading,
  hasMoreData,
  fetchMore,
  error,
  summaryRow,
  onRowClick,
  emptyText,
  searchComponent,
  hasSearchParams = false,
  emptySearchStateTitle,
  emptySearchStateDescription,
  errorStateTitle,
  errorStateDescription,
}: TableProps<ExtendedTData<TData>>) => {
  const tableInstance = useReactTable({
    data,
    columns,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (originalRow) => originalRow.subRows,
    getRowCanExpand: (row) => Boolean(row.original.canExpand),
    defaultColumn: {
      size: undefined,
      minSize: undefined,
    },
  });

  const canSomeRowsExpand = tableInstance.getCanSomeRowsExpand();

  const isEmpty = data.length === 0 && !isLoading && !hasSearchParams && !error;
  const isEmptyWithSearchParams =
    data.length === 0 && !isLoading && hasSearchParams;
  const isLoadingEmptyState = data.length === 0 && isLoading;

  if (isEmpty) {
    return <EmptyDataBlockWithContainer emptyText={emptyText} />;
  }

  return (
    <div className={styles.container} role="table">
      <div className={styles.content}>
        <SearchBlock searchComponent={searchComponent} />
        <div
          className={classNames(styles.table, {
            [styles.withEmptySearchParams]:
              isEmptyWithSearchParams || isLoadingEmptyState,
          })}
        >
          <TableHeader
            canSomeRowsExpand={canSomeRowsExpand}
            columns={tableInstance.getAllColumns()}
            headerGroups={tableInstance.getHeaderGroups()}
          />
          <TableBody
            error={error}
            summaryRow={summaryRow}
            isEmpty={isEmptyWithSearchParams}
            isLoading={isLoadingEmptyState}
            columns={tableInstance.getAllColumns()}
            rows={tableInstance.getRowModel().rows}
            fetchMore={fetchMore}
            onRowClick={onRowClick}
            emptySearchStateTitle={emptySearchStateTitle}
            emptySearchStateDescription={emptySearchStateDescription}
            errorStateTitle={errorStateTitle}
            errorStateDescription={errorStateDescription}
            canSomeRowsExpand={canSomeRowsExpand}
          />
        </div>
      </div>
      {!isEmptyWithSearchParams && !isLoadingEmptyState && (
        <div className={styles.loadMoreStatusContainer}>
          <LoadMoreStatus isLoading={isLoading} hasMoreData={hasMoreData} />
        </div>
      )}
    </div>
  );
};
