import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { RefObject, useRef } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import { ExtendedTData } from 'ui/Table/types';
import styles from './TableRow.module.scss';
import { TableRowCell } from '../TableRowCell';
import { NestedTableRowFooter } from '../NestedTableRowFooter';
import { ROW_ANIMATION_DURATION } from '../../const';

interface TableRowProps<TData> {
  row: Row<ExtendedTData<TData>>;
  canSomeRowsExpand: boolean;
  rowRef: RefObject<HTMLDivElement>;
  onClick?: (arg: TData) => void;
}

export const TableRow = <TData,>({
  row,
  onClick,
  canSomeRowsExpand,
  rowRef,
}: TableRowProps<TData>) => {
  const nestedFooterRef = useRef<HTMLDivElement>(null);

  let nonPinnedColumnsWidth = 0;

  const parentRow = row.getParentRow();
  const isParentRowExpanded = parentRow?.getIsExpanded() ?? false;

  const isNestedRow = row.depth > 0;
  const { nestedInfo } =
    isNestedRow && parentRow ? parentRow.original : row.original;
  const isNestedRowsHasMoreData =
    (isNestedRow && nestedInfo?.hasMoreData) ?? false;
  const isNestedRowsLoading = nestedInfo?.isLoading ?? false;

  const isLastSubRow =
    isNestedRow && parentRow && row.index === parentRow.subRows.length - 1;

  const showNestedFooter =
    ((isLastSubRow && isParentRowExpanded) ||
      (row.getIsExpanded() && !row.subRows?.length)) &&
    nestedInfo;

  const handleRowClick = () => {
    if (onClick && !window.getSelection()?.toString() && !isNestedRow) {
      onClick(row.original);
    }
  };

  return (
    <>
      <div
        className={classnames(styles.rowContainer, {
          [styles.clickable]: Boolean(onClick) && !isNestedRow,
          [styles.withExpand]: canSomeRowsExpand,
        })}
        data-title={row.id}
        onClick={handleRowClick}
        role="row"
        tabIndex={-1}
        ref={rowRef}
      >
        {row.getVisibleCells().map((cell) => {
          const { meta, enablePinning } = cell.column.columnDef;

          const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
            column: cell.column,
            nonPinnedColumnsWidth,
          });

          nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

          return (
            <TableRowCell
              key={cell.id}
              isPinned={enablePinning}
              left={left}
              align={meta?.align}
              className={classnames({
                [styles.pinned]: enablePinning,
              })}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableRowCell>
          );
        })}
      </div>
      {showNestedFooter && (
        <CSSTransition
          key={`nested-footer-${row.id}`}
          nodeRef={nestedFooterRef}
          timeout={ROW_ANIMATION_DURATION}
          classNames={{
            ...styles,
          }}
        >
          <div className={styles.nestedFooterContainer} ref={nestedFooterRef}>
            <NestedTableRowFooter
              isLoading={isNestedRowsLoading}
              hasMoreData={isNestedRowsHasMoreData}
              moreDataButtonText={nestedInfo?.moreDataButtonText ?? ''}
              loadMore={nestedInfo?.loadMore}
              error={nestedInfo?.error}
            />
          </div>
        </CSSTransition>
      )}
    </>
  );
};
