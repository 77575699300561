import { FC } from 'react';
import { PartnerProfileForm } from './components/PartnerProfileForm';
import { AuthorizationData } from '../AuthorizationData';
import { GetUserIdentities } from '../../queries/generated/GetUserIdentities';
import { InfoBlock } from '../InfoBlock';

interface ProfileDataBlockContentProps {
  partnerIdentities: Extract<
    GetUserIdentities['user'],
    { __typename: 'Partner' }
  >;
  setShouldShowConfirmationModal: (shouldShow: boolean) => void;
}

export const ProfileDataBlockContent: FC<ProfileDataBlockContentProps> = ({
  partnerIdentities,
  setShouldShowConfirmationModal,
}) => {
  const {
    contact,
    firstName,
    lastName,
    birthday,
    username,
    email,
    passwordUpdatedAt,
  } = partnerIdentities;

  const handleDirtyChange = (dirty: boolean) => {
    setShouldShowConfirmationModal(dirty);
  };

  return (
    <>
      <PartnerProfileForm
        onDirtyChange={handleDirtyChange}
        contact={contact}
        firstName={firstName}
        lastName={lastName}
        birthday={birthday}
      />
      <AuthorizationData
        username={username}
        email={email}
        passwordUpdatedAt={passwordUpdatedAt}
      />
      <InfoBlock />
    </>
  );
};
