import { AuthStatuses, useAuth } from 'components/auth';
import { toDataURL } from 'qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthStorageKey, Storage } from 'utils/storage';
import { toast } from 'ui/toast';

const QR_WIDTH = 152;

interface UseCreateTwoFactorResult {
  isLoading: boolean;
  qrCodeBase64?: string;
  secretCode?: string;
}

export const useCreateTwoFactor = (): UseCreateTwoFactorResult => {
  const auth = useAuth();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeBase64, setQrCodeBase64] = useState<string | undefined>();
  const [secretCode, setSecretCode] = useState<string | undefined>();

  useEffect(() => {
    const createTwoFactor = async () => {
      setIsLoading(true);

      try {
        const { secret, qr_content: QRContent } = await auth.createTwoFactor();

        setSecretCode(secret);

        const QRBase64 = await toDataURL(QRContent, {
          margin: 0,
          width: QR_WIDTH,
        });

        setQrCodeBase64(QRBase64);
      } catch (error) {
        toast.error({
          header: 'Error',
          text: t('errors.commonError'),
          toastId: 'twoFactorError',
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (
      auth.user?.authStatus === AuthStatuses.TwoFactorGenerationRequired &&
      !qrCodeBase64
    ) {
      createTwoFactor();
    }
  }, [auth, t, qrCodeBase64]);

  useEffect(() => {
    Storage.authStorage.setValue(AuthStorageKey.ShowTwoFactorSuccess, true);
  }, []);

  return {
    isLoading,
    qrCodeBase64,
    secretCode,
  };
};
