import { TFunction } from 'i18next';
import { Section } from '../types';

export const getContentSectionsData = (
  t: TFunction<'termsOfUse', undefined>
): Array<Section> => [
  {
    id: 'privacyPolicy',
    title: t('content.privacyPolicy.title'),
    descriptions: [t('content.privacyPolicy.description')],
    subsections: [
      {
        id: 'relevance-of-the-privacy-policy',
        title: t('content.privacyPolicy.relevanceOfThePrivacyPolicy.title'),
        descriptions: [
          t('content.privacyPolicy.relevanceOfThePrivacyPolicy.description'),
        ],
      },
      {
        id: 'collection-and-storage-of-personal-data',
        title: t(
          'content.privacyPolicy.collectionAndStorageOfPersonalData.title'
        ),
        descriptions: [],
        listTitle: t(
          'content.privacyPolicy.collectionAndStorageOfPersonalData.subjectTitle'
        ),
        items: [
          {
            text: t(
              'content.privacyPolicy.collectionAndStorageOfPersonalData.subject1'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.collectionAndStorageOfPersonalData.subject2'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.collectionAndStorageOfPersonalData.subject3'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.collectionAndStorageOfPersonalData.subject4'
            ),
          },
        ],
      },
      {
        id: 'use-of-the-information-obtained',
        title: t('content.privacyPolicy.useOfTheInformationObtained.title'),
        descriptions: [],
        listTitle: t(
          'content.privacyPolicy.useOfTheInformationObtained.subjectTitle'
        ),
        items: [
          {
            text: t(
              'content.privacyPolicy.useOfTheInformationObtained.subject1'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.useOfTheInformationObtained.subject2'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.useOfTheInformationObtained.subject3'
            ),
          },
          {
            text: t(
              'content.privacyPolicy.useOfTheInformationObtained.subject4'
            ),
          },
        ],
      },
      {
        id: 'cookie',
        title: t('content.privacyPolicy.cookie.title'),
        descriptions: [
          t('content.privacyPolicy.cookie.description1'),
          t('content.privacyPolicy.cookie.description2'),
        ],
      },
      {
        id: 'information-security',
        title: t('content.privacyPolicy.informationSecurity.title'),
        descriptions: [
          t('content.privacyPolicy.informationSecurity.description1'),
          t('content.privacyPolicy.informationSecurity.description2'),
        ],
      },
      {
        id: 'disclosure-of-data',
        title: t('content.privacyPolicy.disclosureOfData.title'),
        descriptions: [t('content.privacyPolicy.disclosureOfData.description')],
      },
      {
        id: 'contact-information',
        title: t('content.privacyPolicy.contactInformation.title'),
        descriptions: [
          t('content.privacyPolicy.contactInformation.description1'),
        ],
      },
    ],
  },
  {
    id: 'termsAndConditions',
    title: t('content.termAndConditions.title'),
    descriptions: [],
    subsections: [
      {
        id: 'affiliate-program-definitions',
        title: t('content.termAndConditions.affiliateProgramDefinitions.title'),
        descriptions: [
          t(
            'content.termAndConditions.affiliateProgramDefinitions.description'
          ),
          t('content.termAndConditions.affiliateProgramDefinitions.earnings'),
          t('content.termAndConditions.affiliateProgramDefinitions.partner'),
          t(
            'content.termAndConditions.affiliateProgramDefinitions.affiliateProgram'
          ),
          t(
            'content.termAndConditions.affiliateProgramDefinitions.reportingPeriod'
          ),
          t('content.termAndConditions.affiliateProgramDefinitions.players'),
          t('content.termAndConditions.affiliateProgramDefinitions.traffic'),
          t('content.termAndConditions.affiliateProgramDefinitions.cpa'),
          t('content.termAndConditions.affiliateProgramDefinitions.revShare'),
        ],
        items: [],
      },
      {
        id: 'basic-provisions',
        title: t('content.termAndConditions.basicProvisions.title'),
        descriptions: [],
        items: [
          {
            number: '1.1',
            text: t('content.termAndConditions.basicProvisions.1_0'),
          },
          {
            number: '1.2',
            text: t('content.termAndConditions.basicProvisions.1_1'),
          },
          {
            number: '1.3',
            text: t('content.termAndConditions.basicProvisions.1_2'),
          },
          {
            number: '1.4',
            text: t('content.termAndConditions.basicProvisions.1_3'),
          },
          {
            number: '1.5',
            text: t('content.termAndConditions.basicProvisions.1_4'),
          },
          {
            number: '1.6',
            text: t('content.termAndConditions.basicProvisions.1_5'),
            items: [
              {
                number: '1.6.1',
                text: t('content.termAndConditions.basicProvisions.1_5_1'),
              },
            ],
          },
          {
            number: '1.7',
            text: t('content.termAndConditions.basicProvisions.1_6'),
          },
          {
            number: '1.8',
            text: t('content.termAndConditions.basicProvisions.1_7'),
          },
          {
            number: '1.9',
            text: t('content.termAndConditions.basicProvisions.1_8'),
            items: [
              {
                number: '1.9.1',
                text: t('content.termAndConditions.basicProvisions.1_8_1'),
              },
            ],
          },
          {
            number: '1.10',
            text: t('content.termAndConditions.basicProvisions.1_9'),
          },
          {
            number: '1.11',
            text: t('content.termAndConditions.basicProvisions.1_10'),
          },
          {
            number: '1.12',
            text: t('content.termAndConditions.basicProvisions.1_11'),
          },
          {
            number: '1.13',
            text: t('content.termAndConditions.basicProvisions.1_12'),
          },
          {
            number: '1.14',
            text: t('content.termAndConditions.basicProvisions.1_13'),
            items: [
              {
                number: '1.14.1',
                text: t('content.termAndConditions.basicProvisions.1_13_1'),
              },
              {
                number: '1.14.2',
                text: t('content.termAndConditions.basicProvisions.1_13_2'),
              },
              {
                number: '1.14.3',
                text: t('content.termAndConditions.basicProvisions.1_13_3'),
              },
            ],
          },
          {
            number: '1.15',
            text: t('content.termAndConditions.basicProvisions.1_14'),
          },
          {
            number: '1.16',
            text: t('content.termAndConditions.basicProvisions.1_15'),
          },
          {
            number: '1.17',
            text: t('content.termAndConditions.basicProvisions.1_16'),
          },
          {
            number: '1.18',
            text: t('content.termAndConditions.basicProvisions.1_17'),
          },
          {
            number: '1.19',
            text: t('content.termAndConditions.basicProvisions.1_18'),
          },
          {
            number: '1.20',
            text: t('content.termAndConditions.basicProvisions.1_19'),
            items: [
              {
                number: '1.20.1',
                text: t('content.termAndConditions.basicProvisions.1_19_1'),
              },
              {
                number: '1.20.2',
                text: t('content.termAndConditions.basicProvisions.1_19_2'),
              },
            ],
          },
          {
            number: '1.21',
            text: t('content.termAndConditions.basicProvisions.1_20'),
          },
          {
            number: '1.22',
            text: t('content.termAndConditions.basicProvisions.1_21'),
          },
        ],
      },
      {
        id: 'formation-and-payment-of-partner-remuneration',
        title: t(
          'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.title'
        ),
        descriptions: [],
        items: [
          {
            number: '2.1',
            text: t(
              'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.2_0'
            ),
          },
          {
            number: '2.2',
            text: t(
              'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.2_1'
            ),
          },
          {
            number: '2.3',
            text: t(
              'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.2_2'
            ),
          },
          {
            number: '2.4',
            text: t(
              'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.2_3'
            ),
          },
          {
            number: '2.5',
            text: t(
              'content.termAndConditions.formationAndPaymentOfPartnerRemuneration.2_4'
            ),
          },
        ],
      },
      {
        id: 'dispute-settlement-procedure',
        title: t('content.termAndConditions.disputeSettlementProcedure.title'),
        descriptions: [
          t('content.termAndConditions.disputeSettlementProcedure.description'),
        ],
        listTitle: t(
          'content.termAndConditions.disputeSettlementProcedure.listTitle'
        ),
        items: [
          {
            text: t(
              'content.termAndConditions.disputeSettlementProcedure.listItem1'
            ),
          },
          {
            text: t(
              'content.termAndConditions.disputeSettlementProcedure.listItem2'
            ),
          },
          {
            text: t(
              'content.termAndConditions.disputeSettlementProcedure.listItem3'
            ),
          },
        ],
      },
    ],
  },
];
