import { FormikErrors } from 'formik';
import { t } from 'i18n';

/**
 * Validates required fields with a custom error message and returns error messages.
 *
 * @param {T} allValues - The form values to be validated.
 * @param {Partial<Record<keyof T, boolean>>} required - An object specifying which fields are required.
 * @param {string} [customMessage=t('errors.requiredField')] - The custom error message to use for required fields.
 *
 * @returns {FormikErrors<T>} - An object containing error messages for each required field that is empty.
 *
 * @throws Will throw an error if the provided `allValues` is not an object.
 *
 * @example
 * const errors = getRequiredErrors(
 *   { name: '', age: '25' },
 *   { name: true }
 * );
 * console.log(errors); // { name: 'errors.requiredField' }
 */
export const getRequiredErrors = <T extends object>(
  allValues: T,
  required: Partial<Record<keyof T, boolean>>,
  customMessage: string = t('errors.requiredField')
): FormikErrors<T> => {
  const errors: Partial<Record<keyof T, string | undefined>> = {};

  Object.keys(allValues).forEach((key) => {
    const typedKey = key as keyof T;
    const value = allValues[typedKey];

    const isValueEmpty = Array.isArray(value)
      ? value.length === 0
      : value === undefined || value === '';

    if (isValueEmpty && required[typedKey]) {
      errors[typedKey] = customMessage;
    }
  });

  return errors as FormikErrors<T>;
};
