import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { DateFormat, formatDate } from 'utils/formatDate';
import { FilterType, NonNullableDateRange, UserTypeName } from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { useGetPartnerCpaStatisticItems } from '../queries/generated/GetPartnerCpaStatisticItems';
import { PartnerCpaStatisticItemData } from '../queries/fragments/generated/PartnerCpaStatisticItemData';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';

const { DateScalar } = DateFormat;

interface UseGetPartnerCpaStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  searchQuery?: string;
}

export const useGetPartnerCpaStatisticItemsQuery = ({
  nonNullableDateRange,
  searchQuery,
  filters,
}: UseGetPartnerCpaStatisticItemsQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, fetchMore, loading, error, isSliced } =
    useGetPartnerCpaStatisticItems({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        first: STATISTIC_ITEMS_PER_PAGE,
        userId: user.id,
        filters,
        ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
      },
      cacheFirstOnPop: true,
      notifyOnNetworkStatusChange: true,
    });

  const statisticItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.cpaStatistic.statisticItems
      : undefined;

  const statisticItems: Array<PartnerCpaStatisticItemData> | undefined =
    useMemo(
      () => statisticItemsConnection?.edges.map((item) => item.node),
      [statisticItemsConnection?.edges]
    );

  const pageInfo = statisticItemsConnection?.pageInfo;

  return { statisticItems, fetchMore, pageInfo, loading, isSliced, error };
};
