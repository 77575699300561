import { FC } from 'react';
import styles from './EmptySearchState.module.scss';

interface EmptySearchStateProps {
  title: string;
  description: string;
}

export const EmptySearchState: FC<EmptySearchStateProps> = ({
  title,
  description,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <h3>{title}</h3>
      <span className={styles.description}>{description}</span>
    </div>
  </div>
);
