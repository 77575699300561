export const COLUMNS_SIZES = {
  mediaNameSize: 256,
  default: 160,
} as const;

export const ALL_COLUMNS = {
  id: 'id',
  owner: 'owner',
  hits: 'statisticInfo.hits',
  hosts: 'statisticInfo.hosts',
  registrations: 'statisticInfo.registrations',
  firstDeposits: 'statisticInfo.firstDeposits',
  depositsAll: 'statisticInfo.depositsAll',
  hold: 'statisticInfo.hold',
  qualified: 'statisticInfo.qualified',
  approved: 'statisticInfo.approved',
  paid: 'statisticInfo.paid',
  rejected: 'statisticInfo.rejected',
  reward: 'statisticInfo.reward',
  averageDeposit: 'statisticInfo.averageDeposit',
  redepositsAll: 'statisticInfo.redepositsAll',
  conversionHostsToRegPercent: 'statisticInfo.conversionHostsToRegPercent',
  conversionRegToDepPercent: 'statisticInfo.conversionRegToDepPercent',
  numberOfRedeposits: 'statisticInfo.numberOfRedeposits',
  withdrawalsAll: 'statisticInfo.withdrawalsAll',
};

export const initialVisibleColumns: Array<string> = [
  ALL_COLUMNS.id,
  ALL_COLUMNS.owner,
  ALL_COLUMNS.hits,
  ALL_COLUMNS.hosts,
  ALL_COLUMNS.registrations,
  ALL_COLUMNS.firstDeposits,
  ALL_COLUMNS.depositsAll,
  ALL_COLUMNS.hold,
  ALL_COLUMNS.qualified,
  ALL_COLUMNS.approved,
  ALL_COLUMNS.paid,
  ALL_COLUMNS.rejected,
  ALL_COLUMNS.reward,
];
