import { NavLinksList, NavLinksListItem } from 'components/NavLinksList';
import { AppRoute } from 'const';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useTranslationUserType } from 'hooks/useTranslationUserType';

export const StatisticNavLinksList: FC = () => {
  const { t } = useTranslation('statistic');
  const { createLocalizedPath } = useCreateLocalizedPath();
  const translationUserType = useTranslationUserType();

  const navLinksListItems: Array<NavLinksListItem> = [
    {
      title: t(`${translationUserType}.action.tabMenu.button.revShare`),
      path: createLocalizedPath(
        composeRoutes([
          AppRoute.Lang.Statistic.root,
          AppRoute.Lang.Statistic.RevShare.root,
        ])
      ),
    },
    {
      title: t(`${translationUserType}.action.tabMenu.button.cpa`),
      path: createLocalizedPath(
        composeRoutes([
          AppRoute.Lang.Statistic.root,
          AppRoute.Lang.Statistic.Cpa.root,
        ])
      ),
    },
  ];

  return <NavLinksList items={navLinksListItems} />;
};
