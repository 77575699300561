import { ApolloError } from '@apollo/client';
import { useAuth } from 'components/auth';
import { FormikErrors, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { checkIsApolloErrorHasErrorCode } from 'utils/checkIsApolloErrorHasErrorCode';
import { errorHandlers } from '../helpers/errorHandlers';
import { mapErrorToField } from '../helpers/mapErrorToField';

interface UseRegisterMutationHandlersParams {
  isCompany: boolean;
}

export const useRegisterMutationHandlers = <
  TRegistrationValues extends {
    email: string;
    createPassword: string;
  },
>({
  isCompany,
}: UseRegisterMutationHandlersParams) => {
  const { t } = useTranslation(['common', 'auth']);
  const auth = useAuth();

  const handleCompleted = async (values: TRegistrationValues) => {
    try {
      await auth.logIn(values.email, values.createPassword);
    } catch {
      toast.error(t('errors.commonError'));
    }
  };

  const handleError = (
    apolloError: ApolloError,
    actions: FormikHelpers<TRegistrationValues>
  ) => {
    let errors: FormikErrors<TRegistrationValues> = {};

    errorHandlers.forEach(({ errorCode, getTranslations }) => {
      if (checkIsApolloErrorHasErrorCode(apolloError, errorCode)) {
        errors = mapErrorToField<TRegistrationValues>({
          apolloError,
          errorCode,
          getTranslations,
          isCompany,
          errors,
        });
      }
    });

    if (Object.keys(errors).length > 0) {
      actions.setErrors(errors);
    } else {
      toast.error(t('errors.commonError'));
    }
  };

  return {
    handleCompleted,
    handleError,
  };
};
