import { FC } from 'react';
import styles from './ErrorState.module.scss';

interface ErrorStateProps {
  title: string;
  description: string;
}

export const ErrorState: FC<ErrorStateProps> = ({ title, description }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <h3>{title}</h3>
      <span className={styles.description}>{description}</span>
    </div>
  </div>
);
