import { useMemo } from 'react';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useAuth } from 'components/auth';
import {
  Currency,
  FilterType,
  NonNullableDateRange,
  UserTypeName,
} from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { useGetPartnerRevShareStatisticItems } from '../queries/generated/GetPartnerRevShareStatisticItems';
import { PartnerRevShareStatisticItemData } from '../queries/fragments/generated/PartnerRevShareStatisticItemData';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';

const { DateScalar } = DateFormat;

interface UseGetPartnerRevShareStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  currency: Currency;
  searchQuery?: string;
}

export const useGetPartnerRevShareStatisticItemsQuery = ({
  nonNullableDateRange,
  currency,
  searchQuery,
  filters,
}: UseGetPartnerRevShareStatisticItemsQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, fetchMore, loading, isSliced, error } =
    useGetPartnerRevShareStatisticItems({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        first: STATISTIC_ITEMS_PER_PAGE,
        userId: user.id,
        filters,
        currency,
        ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
      cacheFirstOnPop: true,
    });

  const statisticItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.revShareStatistic.statisticItems
      : undefined;

  const statisticItems: Array<PartnerRevShareStatisticItemData> | undefined =
    useMemo(
      () => statisticItemsConnection?.edges.map((item) => item.node),
      [statisticItemsConnection?.edges]
    );

  const pageInfo = statisticItemsConnection?.pageInfo;

  return { statisticItems, fetchMore, pageInfo, loading, isSliced, error };
};
