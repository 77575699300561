import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemData } from '../../../../../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { ReferralLinkMediaItemBasicData } from '../../../../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from '../../../../../../../../queries/fragments/generated/TariffForMediaBasicData';
import { TariffForMediaData } from '../../../../../../../../queries/fragments/generated/TariffForMediaData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type ChangeCompanyReferralLinkMediaItemNameVariables =
  SchemaTypes.Exact<{
    input: SchemaTypes.ChangeCompanyReferralLinkMediaItemNameInput;
  }>;

export type ChangeCompanyReferralLinkMediaItemName = {
  __typename: 'Mutation';
  changeCompanyReferralLinkMediaItemName: {
    __typename: 'ChangeCompanyReferralLinkMediaItemNamePayload';
    referralLinkMediaItem: {
      __typename: 'ReferralLinkMediaItem';
      createdAt: string;
      link: string;
      id: string;
      name: string;
      tariff:
        | {
            __typename: 'CpaTariff';
            geolocation: Array<CommonType.Country>;
            createdAt: string;
            id: string;
            name: string;
            baseline: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          }
        | {
            __typename: 'RevShareTariff';
            rewardPercent: string;
            createdAt: string;
            id: string;
            name: string;
          };
      postbackInfo: {
        __typename: 'PostbackInfo';
        commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
      };
      owner:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanyPartner'; id: string; username: string }
        | { __typename: 'Partner'; id: string; username: string };
    };
  };
};

export const ChangeCompanyReferralLinkMediaItemNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeCompanyReferralLinkMediaItemName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ChangeCompanyReferralLinkMediaItemNameInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'changeCompanyReferralLinkMediaItemName',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralLinkMediaItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ReferralLinkMediaItem',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ReferralLinkMediaItemData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReferralLinkMediaItemData.definitions,
    ...ReferralLinkMediaItemBasicData.definitions,
    ...TariffForMediaBasicData.definitions,
    ...TariffForMediaData.definitions,
  ],
} as unknown as DocumentNode;
export type ChangeCompanyReferralLinkMediaItemNameMutationFn =
  Apollo.MutationFunction<
    ChangeCompanyReferralLinkMediaItemName,
    ChangeCompanyReferralLinkMediaItemNameVariables
  >;

/**
 * __useChangeCompanyReferralLinkMediaItemName__
 *
 * To run a mutation, you first call `useChangeCompanyReferralLinkMediaItemName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCompanyReferralLinkMediaItemName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCompanyReferralLinkMediaItemName, { data, loading, error }] = useChangeCompanyReferralLinkMediaItemName({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCompanyReferralLinkMediaItemName(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeCompanyReferralLinkMediaItemName,
    ChangeCompanyReferralLinkMediaItemNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeCompanyReferralLinkMediaItemName,
    ChangeCompanyReferralLinkMediaItemNameVariables
  >(ChangeCompanyReferralLinkMediaItemNameDocument, options);
}
export type ChangeCompanyReferralLinkMediaItemNameHookResult = ReturnType<
  typeof useChangeCompanyReferralLinkMediaItemName
>;
export type ChangeCompanyReferralLinkMediaItemNameMutationResult =
  Apollo.MutationResult<ChangeCompanyReferralLinkMediaItemName>;
export type ChangeCompanyReferralLinkMediaItemNameMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeCompanyReferralLinkMediaItemName,
    ChangeCompanyReferralLinkMediaItemNameVariables
  >;
