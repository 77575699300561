import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaReferralLinkStatisticInfoData } from './CpaReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type CpaReferralLinkStatisticItemData = {
  __typename: 'CpaReferralLinkStatisticItem';
  id: string;
  playerName?: string | null;
  referralStatus: SchemaTypes.ReferralStatus;
  target: string;
  statisticInfo: {
    __typename: 'CpaReferralLinkStatisticInfo';
    numberOfRedeposits: number;
    wasFD: boolean;
    averageDeposit: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    depositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    redepositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    withdrawalsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
  };
};

export const CpaReferralLinkStatisticItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CpaReferralLinkStatisticItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CpaReferralLinkStatisticItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statisticInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CpaReferralLinkStatisticInfoData',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'target' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
