import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { useCallback } from 'react';

export const useSearchHandler = () => {
  const [, setSearchParams] = useSearchParams();

  const handleSearchParams = useCallback(
    (value: string) => {
      setSearchParams(
        () => {
          const params = new URLSearchParams(window.location.search);

          if (value.length === 0) {
            params.delete('search');
          } else {
            params.set('search', value);
          }

          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const debouncedSearch = useDebounce(handleSearchParams);

  const handleSearchChange = (value: string) => {
    if (value.length === 0 || value.length > 1) {
      debouncedSearch(value);
    }
  };

  return { onSearchChange: handleSearchChange };
};
