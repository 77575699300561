import { TFunction } from 'i18next';
import { NavigationSection } from '../types';

export const getNavigationSectionsData = (
  t: TFunction<'termsOfUse', undefined>
): Array<NavigationSection> => [
  {
    title: t('action.sections.privacyPolicy.privacyPolicy'),
    links: [
      {
        id: 'relevance-of-the-privacy-policy',
        label: t('action.sections.privacyPolicy.relevanceOfThePrivacyPolicy'),
      },
      {
        id: 'collection-and-storage-of-personal-data',
        label: t(
          'action.sections.privacyPolicy.collectionAndStorageOfPersonalData'
        ),
      },
      {
        id: 'use-of-the-information-obtained',
        label: t('action.sections.privacyPolicy.useOfTheInformationObtained'),
      },
      {
        id: 'cookie',
        label: t('action.sections.privacyPolicy.cookie'),
      },
      {
        id: 'disclosure-of-data',
        label: t('action.sections.privacyPolicy.disclosureOfData'),
      },
      {
        id: 'contact-information',
        label: t('action.sections.privacyPolicy.contactInformation'),
      },
    ],
  },
  {
    title: t('action.sections.termsAndConditions.termsAndConditions'),
    links: [
      {
        id: 'affiliate-program-definitions',
        label: t(
          'action.sections.termsAndConditions.affiliateProgramDefinitions'
        ),
      },
      {
        id: 'basic-provisions',
        label: t('action.sections.termsAndConditions.BasicProvisions'),
      },
      {
        id: 'formation-and-payment-of-partner-remuneration',
        label: t(
          'action.sections.termsAndConditions.formationAndPaymentOfPartnerRemuneration'
        ),
      },
      {
        id: 'dispute-settlement-procedure',
        label: t(
          'action.sections.termsAndConditions.disputeSettlementProcedure'
        ),
      },
    ],
  },
];
