import { useCallback } from 'react';
import { Currency } from 'types';
import { toast } from 'ui/toast';
import { useAuth } from 'components/auth';
import { useApolloClient } from '@apollo/client';
import { useSwitchCompanyCurrentCurrencyAccount } from '../queries/generated/SwitchCompanyCurrentCurrencyAccount';
import {
  GetAvailableCurrencyAccounts,
  GetAvailableCurrencyAccountsDocument,
} from '../../../queries/generated/GetAvailableCurrencyAccounts';
import { AvailableCurrencyAccountsData } from '../../../queries/fragments/generated/AvailableCurrencyAccountsData';

export const useSwitchCompanyCurrentCurrencyAccountMutation = () => {
  const client = useApolloClient();
  const { user } = useAuth();

  const [switchCompanyCurrentCurrencyAccount, { loading }] =
    useSwitchCompanyCurrentCurrencyAccount();

  const availableCurrencyAccounts: GetAvailableCurrencyAccounts | null =
    client.readQuery<GetAvailableCurrencyAccounts>({
      query: GetAvailableCurrencyAccountsDocument,
      variables: {
        userId: user.id,
      },
    });

  const accounts: Array<AvailableCurrencyAccountsData | null> | undefined =
    availableCurrencyAccounts?.user.availableCurrencyAccounts;

  const switchCurrentCurrencyAccount = useCallback(
    (currency: Currency) => {
      if (!currency) {
        return undefined;
      }

      return switchCompanyCurrentCurrencyAccount({
        variables: {
          input: {
            currency,
            companyId: user.id,
          },
        },
        onError: (error) =>
          toast.error({
            header: 'Error',
            text: error.message,
          }),
        optimisticResponse: ({ input }, { IGNORE }) => {
          if (!accounts) {
            return IGNORE;
          }

          const updatedAccounts = accounts
            .filter(
              (account): account is NonNullable<typeof account> =>
                account !== null
            )
            .map((account) => {
              if (account?.currency && account.currency === input.currency) {
                return {
                  ...account,
                  isCurrent: true,
                };
              }

              return { ...account, isCurrent: false };
            });

          return {
            __typename: 'Mutation',
            switchCompanyCurrentCurrencyAccount: {
              __typename: 'SwitchCompanyCurrentCurrencyAccountPayload',
              company: {
                __typename: 'Company',
                id: user.id,
                availableCurrencyAccounts: updatedAccounts,
              },
            },
          };
        },
      });
    },
    [switchCompanyCurrentCurrencyAccount, user.id, accounts]
  );

  return {
    switchCurrentCurrencyAccount,
    loading,
  };
};
