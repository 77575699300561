import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

const VISIBILITY_THRESHOLD = 0.7;

const defaultObserverOptions = {
  rootMargin: '-10% 0px 0px',
  threshold: [0, 0.1, 0.25, 0.5, 0.75, 1],
};

interface HandleObserverParams {
  entries: Array<IntersectionObserverEntry>;
  visibilityMap: Map<string, number>;
  elementsMap: Map<string, Element>;
}

interface UseSectionsObserverParams {
  onSectionChange: (newTitle: string) => void;
}

export const useSectionsObserver = ({
  onSectionChange,
}: UseSectionsObserverParams) => {
  const observableItemsRef = useRef<Record<string, Array<HTMLElement>>>({});
  const [activeSectionId, setActiveSectionId] = useState('');

  const { hash } = useLocation();
  const navigate = useNavigate();

  const setObservableRef = useCallback(
    (el: HTMLElement | null, sectionId: string, index: number) => {
      if (!el) return;

      if (!observableItemsRef.current[sectionId]) {
        observableItemsRef.current[sectionId] = [el];

        return;
      }

      observableItemsRef.current[sectionId][index] = el;
    },
    []
  );

  const findVisibleSection = (visibilityMap: Map<string, number>): string => {
    const visibleSections = [...visibilityMap.entries()]
      .filter(([, ratio]) => ratio > 0)
      .sort((a, b) => b[1] - a[1]);

    return visibleSections.length > 0 ? visibleSections[0][0] : '';
  };

  const handleObserver = useCallback(
    ({ entries, visibilityMap, elementsMap }: HandleObserverParams) => {
      const currentHash = hash.replace('#', '');

      let newActiveSectionId = '';

      entries.forEach((entry) => {
        visibilityMap.set(entry.target.id, entry.intersectionRatio);
        elementsMap.set(entry.target.id, entry.target);
      });

      const isAnchoredSectionVisible =
        (currentHash && visibilityMap.get(currentHash)) ??
        VISIBILITY_THRESHOLD < 0;

      if (isAnchoredSectionVisible) {
        newActiveSectionId = currentHash;
      } else {
        newActiveSectionId = findVisibleSection(visibilityMap);
      }

      if (newActiveSectionId) {
        setActiveSectionId(newActiveSectionId);

        const currentActiveSection = elementsMap.get(newActiveSectionId);
        const title = currentActiveSection?.getAttribute('data-title') ?? '';

        onSectionChange(title);
      }
    },
    [hash, onSectionChange]
  );

  useEffect(() => {
    const visibilityMap = new Map<string, number>();
    const elementsMap = new Map<string, Element>();

    const observer = new IntersectionObserver((entries) => {
      handleObserver({ entries, visibilityMap, elementsMap });
    }, defaultObserverOptions);

    const allElements = Object.values(observableItemsRef.current).flat();

    allElements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  useEffect(() => {
    const onScrollEnd = () => {
      if (hash) {
        navigate({ hash: '' }, { replace: true });
      }
    };

    window.addEventListener('scrollend', onScrollEnd);

    return () => {
      window.removeEventListener('scrollend', onScrollEnd);
    };
  }, [navigate, hash]);

  return {
    setObservableRef,
    activeSectionId,
  };
};
