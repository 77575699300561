import { useAuth } from 'components/auth';
import { FilterType, NonNullableDateRange, UserTypeName } from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetPartnerCpaStatisticTotal } from '../queries/generated/GetPartnerCpaStatisticTotal';

const { DateScalar } = DateFormat;

interface UseGetPartnerCpaStatisticTotalQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  searchQuery?: string;
}

export const useGetPartnerCpaStatisticTotalQuery = ({
  nonNullableDateRange,
  searchQuery,
  filters,
}: UseGetPartnerCpaStatisticTotalQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, loading, error } = useGetPartnerCpaStatisticTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
      filters,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    cacheFirstOnPop: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.cpaStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
