import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useUpdateCompanyIdentities } from '../queries/generated/UpdateCompanyIdentities';
import { CompanyIdentitiesValues } from '../types';

type UpdateCompanyIdentities = (
  companyIdentitiesValues: CompanyIdentitiesValues,
  onCompleted: () => void
) => void;

interface UseUpdateCompanyIdentitiesMutationParams {
  companyId: string;
}

export const useUpdateCompanyIdentitiesMutation = ({
  companyId,
}: UseUpdateCompanyIdentitiesMutationParams) => {
  const { t } = useTranslation();

  const [updateCompanyIdentitiesMutation, { loading }] =
    useUpdateCompanyIdentities();

  const updateCompanyIdentities: UpdateCompanyIdentities = (
    { website, contactValue, contactType },
    onCompleted
  ) => {
    updateCompanyIdentitiesMutation({
      variables: {
        input: {
          id: companyId,
          website: website || null,
          contact: {
            type: contactType,
            value: contactValue,
          },
        },
      },
      onError: () => {
        toast.error(t('errors.updateDataError'));
      },
      onCompleted: () => {
        onCompleted();
        toast.success(t('updateDataSuccess'));
      },
    });
  };

  return { updateCompanyIdentities, loading };
};
