import { useState } from 'react';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { STATISTIC_CURSOR_COLUMN } from 'pages/Statistic/pages/StatisticGeneral/const';
import { initialVisibleColumns } from '../../../const';
import { CompanyCpaStatisticItemData } from '../queries/fragments/generated/CompanyCpaStatisticItemData';

export const useCopmanyCpaStatisticItemsColumnsManager = ({
  columns,
}: {
  columns: Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>;
}) => {
  const requiredColumns = columns.filter((column) =>
    initialVisibleColumns.includes(column.accessorKey)
  );

  const [visibleColumns, setVisibleColumns] =
    useState<Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>>(
      requiredColumns
    );

  const optionalColumns = columns.filter(
    (column) => !initialVisibleColumns.includes(column.accessorKey)
  );

  const updateVisibleColumns = (newColumns: Array<string>) => {
    const parsedOptionalColumns = optionalColumns.filter((column) =>
      newColumns.includes(column.accessorKey)
    );

    const cursorColumnIndex = requiredColumns.findIndex(
      (column) => column.accessorKey === STATISTIC_CURSOR_COLUMN
    );

    if (!cursorColumnIndex) {
      setVisibleColumns([...requiredColumns, ...parsedOptionalColumns]);

      return;
    }

    const newVisibleColumns = requiredColumns.toSpliced(
      cursorColumnIndex + 1,
      0,
      ...parsedOptionalColumns
    );

    setVisibleColumns(newVisibleColumns);
  };

  return {
    optionalColumns,
    visibleColumns,
    updateVisibleColumns,
  };
};
