import classNames from 'classnames';
import { useField } from 'formik';
import { Placement } from '@floating-ui/react';
import { ReactComponent as DoneIcon } from 'assets/icons/line/DoneSmall.svg';
import { FormValidationWrapper } from '../FormValidationWrapper/FormValidationWrapper';
import styles from './Checkbox.module.scss';

import {
  CheckboxSize,
  ERROR_TOOLTIP_OFFSET_DEFAULT,
  ERROR_TOOLTIP_OFFSET_SMALL,
} from './const';
import { Icon, IconSize } from '../../../Icon';

const mapCheckboxSizeToIconSize: Record<CheckboxSize, IconSize> = {
  [CheckboxSize.Default]: IconSize.Large,
  [CheckboxSize.Small]: IconSize.Small,
};

const mapCheckboxSizeToStyle: Record<CheckboxSize, string> = {
  [CheckboxSize.Default]: styles.default,
  [CheckboxSize.Small]: styles.small,
};

interface CheckboxProps {
  label?: string;
  name: string;
  checkboxSize?: CheckboxSize;
  className?: string;
  onChange?: (value: boolean) => void;
  errorTooltipPlacement?: Placement;
  horizontalOffset?: number;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  checkboxSize = CheckboxSize.Default,
  className,
  onChange,
  errorTooltipPlacement,
  horizontalOffset = CheckboxSize.Default
    ? ERROR_TOOLTIP_OFFSET_DEFAULT
    : ERROR_TOOLTIP_OFFSET_SMALL,
}) => {
  const [field, , setters] = useField(name);

  const handleClick = () => {
    setters.setValue(!field.value);
    onChange?.(!field.value);

    setTimeout(() => {
      setters.setTouched(true, true);
    }, 0);
  };

  return (
    <FormValidationWrapper
      name={name}
      errorTooltipPlacement={errorTooltipPlacement}
      horizontalOffset={horizontalOffset}
    >
      <button
        type="button"
        className={classNames(styles.checkboxButton, className)}
        onClick={handleClick}
      >
        <div
          data-testid="checkboxControl"
          className={classNames(
            styles.checkboxControl,
            field.value && styles.checked,
            mapCheckboxSizeToStyle[checkboxSize]
          )}
        >
          <Icon
            size={mapCheckboxSizeToIconSize[checkboxSize]}
            hasDefaultColor={false}
            containerClassName={styles.iconContainer}
            iconClassName={styles.icon}
            iconComponent={DoneIcon}
          />
        </div>
        {label && <span className={styles.label}>{label}</span>}
      </button>
    </FormValidationWrapper>
  );
};
