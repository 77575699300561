import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { BottomSheet } from 'ui/BottomSheet';
import { BottomSheetProps } from 'ui/BottomSheet/BottomSheet';
import { useDropdownTrigger } from './hooks/useDropdownTrigger';
import { DropdownContainer } from './components/DropdownContainer';
import { DropdownPosition } from './const';
import styles from './Dropdown.module.scss';

export interface DropdownProps {
  children: ReactNode;
  isOpenForced?: boolean;
  addStyleToContainer?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  trigger?: ReactNode;
  position?: DropdownPosition;
  withStyleMaxContent?: boolean;
  hasGrayOutline?: boolean;
  title: string;
  bottomSheetProps?: Pick<BottomSheetProps, 'contentClassName'>;
  /**
   * TODO: remove `withBottomSheet` prop when URL input component is implemented
   * Issue https://eyeconweb.atlassian.net/browse/AMS-1859
   */
  withBottomSheet?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  isOpenForced,
  addStyleToContainer = true,
  onOpenChange,
  trigger,
  position,
  withStyleMaxContent = true,
  hasGrayOutline,
  title,
  withBottomSheet = true,
  bottomSheetProps,
}) => {
  const { isOpen, handleClick, handleBlur } = useDropdownTrigger({
    isOpenForced,
    onOpenChange,
  });

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  return (
    <>
      <div
        className={classNames(
          styles.container,
          withStyleMaxContent && styles.withStyleMaxContent
        )}
        onBlur={(e) => {
          if (!isMobile) {
            handleBlur(e);
          }
        }}
      >
        {trigger && <div onClick={handleClick}>{trigger}</div>}
        {isOpen && (!isMobile || !withBottomSheet) && (
          <DropdownContainer
            position={position}
            withStyleMaxContent={withStyleMaxContent}
            addStyleToContainer={addStyleToContainer}
            hasGrayOutline={hasGrayOutline}
          >
            {children}
          </DropdownContainer>
        )}
      </div>
      {isMobile && withBottomSheet && (
        <BottomSheet
          title={title}
          isOpen={isOpen}
          onClose={handleClick}
          onBlur={handleBlur}
          contentClassName={bottomSheetProps?.contentClassName}
        >
          {children}
        </BottomSheet>
      )}
    </>
  );
};
