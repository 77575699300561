import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useChangeCompanyReferralLinkMediaItemNameMutation } from './useChangeCompanyReferralLinkMediaItemNameMutation';
import { useChangePartnerReferralLinkMediaItemNameMutation } from './useChangePartnerReferralLinkMediaItemNameMutation';

export const useChangeReferralLinkMediaItemName = () => {
  const { t } = useTranslation('common');

  const { userTypeName, loading: userTypeNameLoading } =
    useGetUserTypeNameQuery({
      onError: () => {
        toast.error(t('errors.commonError'));
      },
    });

  const useChangeReferralLinkMediaItemNameByUserType =
    userTypeName === UserTypeName.Partner
      ? useChangePartnerReferralLinkMediaItemNameMutation
      : useChangeCompanyReferralLinkMediaItemNameMutation;

  const result = useChangeReferralLinkMediaItemNameByUserType();

  return { ...result, loading: userTypeNameLoading || result.loading };
};
