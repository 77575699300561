import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Button, ButtonProps, ButtonSize, ButtonTheme } from 'ui/Button';
import { Dropdown, DropdownProps } from 'ui/Dropdown';
import { BottomSheetProps } from 'ui/BottomSheet/BottomSheet';
import { ReactComponent as AltArrowDown } from 'assets/icons/line/AltArrowDown.svg';
import styles from './DropdownWithButton.module.scss';

interface DropdownWithButtonProps {
  buttonContent: ReactNode;
  children: ReactNode;
  buttonProps?: Omit<ButtonProps, 'children'>;
  dropdownProps: Omit<DropdownProps, 'trigger' | 'children'>;
  bottomSheetProps?: Pick<BottomSheetProps, 'contentClassName'>;
}

export const DropdownWithButton: FC<DropdownWithButtonProps> = ({
  children,
  buttonContent,
  buttonProps,
  dropdownProps,
  bottomSheetProps,
}) => {
  const { isOpenForced, onOpenChange, ...restDropdownProps } =
    dropdownProps ?? {};

  const [isActive, setIsActive] = useState<boolean>(isOpenForced || false);

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  const handleOpenChange = (isOpen: boolean) => {
    setIsActive(isOpen);
    onOpenChange?.(isOpen);
  };

  return (
    <Dropdown
      isOpenForced={isOpenForced}
      onOpenChange={handleOpenChange}
      {...restDropdownProps}
      bottomSheetProps={bottomSheetProps}
      trigger={
        <Button
          className={classNames(styles.button, isActive && styles.activeButton)}
          iconConfig={{
            iconComponent: AltArrowDown,
            containerClassName: classNames(
              styles.icon,
              isActive && !isMobile && styles.activeIcon
            ),
          }}
          theme={ButtonTheme.Grey}
          size={ButtonSize.Small}
          {...buttonProps}
        >
          <div className={styles.buttonContent}>{buttonContent}</div>
        </Button>
      }
    >
      {children}
    </Dropdown>
  );
};
