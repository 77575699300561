import { ButtonHTMLAttributes, FC } from 'react';
import { ReactComponent as FiltersIcon } from 'assets/icons/line/Filters.svg';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import classNames from 'classnames';
import styles from './FiltersButton.module.scss';

interface FiltersButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  filterButtonText: string;
  isOpen?: boolean;
}

export const FiltersButton: FC<FiltersButtonProps> = ({
  onClick,
  isOpen,
  disabled,
  filterButtonText,
  ...rest
}) => (
  <Button
    className={classNames(isOpen && styles.active)}
    iconConfig={{ iconComponent: FiltersIcon, before: true }}
    textWrapperClassName={styles.buttonText}
    theme={ButtonTheme.BlueLight}
    size={ButtonSize.Small}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {filterButtonText}
  </Button>
);
