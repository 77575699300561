import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table } from 'ui/Table';
import { Currency, NonNullableDateRange } from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { useLoadMore } from 'hooks/useLoadMore';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { useGetPartnerRevShareStatisticItemsQuery } from './hooks/useGetPartnerRevShareStatisticItemsQuery';
import { usePartnerRevShareStatisticItemsColumns } from './hooks/usePartnerRevShareStatisticItemsColumns';
import { useGeneratePartnerRevShareStatisticSummaryRow } from './hooks/useGeneratePartnerRevShareStatisticSummaryRow';
import { PartnerRevShareStatisticItemData } from './queries/fragments/generated/PartnerRevShareStatisticItemData';
import { PartnerRevShareStatisticTableSearch } from './components/PartnerRevShareStatisticTableSearch';

interface PartnerRevShareStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
  updateCurrency: (currency: Currency) => void;
  currency: Currency;
}

export const PartnerRevShareStatisticTable: FC<
  PartnerRevShareStatisticTableProps
> = ({ nonNullableDateRange, currency, updateCurrency }) => {
  const { t } = useTranslation('statistic');
  const [searchParams] = useSearchParams();
  const { createLocalizedPath } = useCreateLocalizedPath();
  const { parseUrlFilters } = useFiltersHandlers();

  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const { filters, formFilters } =
    parseUrlFilters<PartnerStatisticFilterType>();

  const navigate = useNavigate();

  const {
    statisticItems,
    fetchMore,
    pageInfo,
    loading: itemsQueryLoading,
    isSliced,
    error: itemsQueryError,
  } = useGetPartnerRevShareStatisticItemsQuery({
    nonNullableDateRange,
    currency,
    searchQuery,
    filters,
  });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGeneratePartnerRevShareStatisticSummaryRow({
    nonNullableDateRange,
    currency,
    searchQuery,
    filters,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = usePartnerRevShareStatisticItemsColumns();

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const handleClickOnRow = ({ id }: PartnerRevShareStatisticItemData) => {
    const path = createLocalizedPath(
      composeRoutes(
        [
          AppRoute.Lang.Statistic.root,
          AppRoute.Lang.Statistic.RevShare.root,
          id,
        ],
        {
          searchParams,
        }
      )
    );

    navigate(path);
  };

  const tableData = statisticItems ?? [];

  return (
    <Table
      onRowClick={handleClickOnRow}
      columns={columns}
      data={tableData}
      error={error}
      emptyText={t('partner.content.placeholder.noData')}
      summaryRow={summaryRowData}
      isLoading={loading || isSliced}
      hasSearchParams={!!searchQuery || !!filters.length}
      hasMoreData={!!pageInfo?.hasNextPage}
      emptySearchStateTitle={t('partner.content.placeholder.noDataHeader')}
      emptySearchStateDescription={t(
        'partner.content.placeholder.noDataSubheader'
      )}
      errorStateTitle={t('placeholder.loadingError.loadingErrorHeader')}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheader'
      )}
      fetchMore={loadMore}
      searchComponent={
        <PartnerRevShareStatisticTableSearch
          initialValues={{
            search: searchQuery,
            currency,
            filters: formFilters,
          }}
          updateCurrency={updateCurrency}
        />
      }
    />
  );
};
