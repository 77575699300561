import { useAuth } from 'components/auth';
import { NonNullableDateRange } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetCpaStatisticDetailedTotal } from '../query/generated/GetCpaStatisticDetailedTotal';

const { DateScalar } = DateFormat;

interface UseGetCpaStatisticDetailedTotalQueryParams {
  cpaMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
  searchQuery?: string;
}

export const useGetCpaStatisticDetailedTotalQuery = ({
  cpaMediaItemId,
  nonNullableDateRange,
  searchQuery,
}: UseGetCpaStatisticDetailedTotalQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, loading, error } = useGetCpaStatisticDetailedTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
      cpaMediaItemId,
      ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
    },
    cacheFirstOnPop: true,
  });

  const statisticTotal = data?.user.referralLinkMediaItem.cpaStatistic.total;

  return { statisticTotal, loading, error };
};
