import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { DatePickerWithRangeButton } from 'ui/DatePickerWithRangeButton';
import { DropdownPosition } from 'ui/Dropdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StatisticIcon } from 'assets/icons/colors/Chart.svg';
import { TopBar } from 'components/TopBar';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { StatisticTableByType } from './components/StatisticTableByType';
import { StatisticOutletContext } from '../../types';
import { useStatisticDateRange } from '../../hooks/useStatisticDateRange';
import { StatisticNavLinksList } from './components/StatisticNavLinksList';
import { useStatisticCurrency } from '../../hooks/useStatisticCurrency';

export const StatisticGeneral: FC = () => {
  const { t } = useTranslation('statistic');
  const { statisticType } = useOutletContext<StatisticOutletContext>();
  const translationUserType = useTranslationUserType();

  const {
    updateDateRange,
    error,
    dateRange,
    defaultDateRange,
    loading: dateRangeLoading,
  } = useStatisticDateRange();

  const {
    currency,
    updateCurrency,
    loading: currencyLoading,
  } = useStatisticCurrency();

  if (dateRangeLoading || currencyLoading) {
    return <Loader />;
  }

  if (error || !dateRange || !defaultDateRange || !statisticType || !currency) {
    return <Error />;
  }

  const [startDate, endDate] = dateRange;

  return (
    <>
      <TopBar
        title={t(`${translationUserType}.content.header.header`)}
        icon={StatisticIcon}
      />
      <SubHeaderBlock>
        <StatisticNavLinksList />
        <DatePickerWithRangeButton
          onDateSubmit={updateDateRange}
          startDateInitial={startDate}
          endDateInitial={endDate}
          dateResetTo={defaultDateRange}
          dropdownPosition={DropdownPosition.RightBottom}
        />
      </SubHeaderBlock>
      <StatisticTableByType
        statisticType={statisticType}
        nonNullableDateRange={dateRange}
        currency={currency}
        updateCurrency={updateCurrency}
      />
    </>
  );
};
