import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { FilterType, NonNullableDateRange } from 'types';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { useGetCompanyCpaStatisticTotalQuery } from './useGetCompanyCpaStatisticTotalQuery';
import { CompanyCpaStatisticInfoData } from '../queries/fragments/generated/CompanyCpaStatisticInfoData';
import { CompanyCpaStatisticItemData } from '../queries/fragments/generated/CompanyCpaStatisticItemData';
import { ALL_COLUMNS } from '../../../const';

type GetSummaryRowData = (
  info: CompanyCpaStatisticInfoData,
  visibleColumns: Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = (
  {
    hits,
    hosts,
    registrations,
    firstDeposits,
    depositsAll,
    hold,
    qualified,
    approved,
    paid,
    rejected,
    reward,
    averageDeposit,
    redepositsAll,
    conversionHostsToRegPercent,
    conversionRegToDepPercent,
    numberOfRedeposits,
    withdrawalsAll,
  },
  visibleColumns
) => {
  const summaryRows: Record<string, ReactNode> = {
    [ALL_COLUMNS.hits]: hits,
    [ALL_COLUMNS.hosts]: hosts,
    [ALL_COLUMNS.registrations]: registrations,
    [ALL_COLUMNS.firstDeposits]: firstDeposits,
    [ALL_COLUMNS.depositsAll]: getMoneyAmount(depositsAll),
    [ALL_COLUMNS.hold]: hold,
    [ALL_COLUMNS.qualified]: qualified,
    [ALL_COLUMNS.approved]: approved,
    [ALL_COLUMNS.paid]: paid,
    [ALL_COLUMNS.rejected]: rejected,
    [ALL_COLUMNS.reward]: getMoneyAmount(reward),
    [ALL_COLUMNS.averageDeposit]: getMoneyAmount(averageDeposit),
    [ALL_COLUMNS.redepositsAll]: getMoneyAmount(redepositsAll),
    [ALL_COLUMNS.conversionHostsToRegPercent]: `${conversionHostsToRegPercent}%`,
    [ALL_COLUMNS.conversionRegToDepPercent]: `${conversionRegToDepPercent}%`,
    [ALL_COLUMNS.numberOfRedeposits]: numberOfRedeposits,
    [ALL_COLUMNS.withdrawalsAll]: getMoneyAmount(withdrawalsAll),
  };

  const result = visibleColumns.reduce<Array<ReactNode>>((acc, column) => {
    if (column.accessorKey in summaryRows) {
      return [...acc, summaryRows[column.accessorKey]];
    }

    return acc;
  }, []);

  return result;
};

interface UseGenerateCompanyCpaStatisticSummaryRowParams {
  nonNullableDateRange: NonNullableDateRange;
  visibleColumns: Array<AccessorKeyColumnDef<CompanyCpaStatisticItemData>>;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  searchQuery?: string;
}

export const useGenerateCompanyCpaStatisticSummaryRow = ({
  nonNullableDateRange,
  visibleColumns,
  searchQuery,
  filters,
}: UseGenerateCompanyCpaStatisticSummaryRowParams) => {
  const { t } = useTranslation('statistic');

  const { totalStatistic, error, loading } =
    useGetCompanyCpaStatisticTotalQuery({
      nonNullableDateRange,
      searchQuery,
      filters,
    });

  const summaryRowData = totalStatistic
    ? [
        t('company.content.dataTable.cpa.allMedia'),
        undefined,
        ...getSummaryRowData(totalStatistic, visibleColumns),
      ]
    : undefined;

  return { summaryRowData, loading, error };
};
