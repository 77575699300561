import { FilterType, FormFiltersType } from 'types';

export const serializeFilters = <T extends string>(
  filters: FormFiltersType<T>
): Array<FilterType<T>> =>
  Object.keys(filters).reduce<Array<FilterType<T>>>((acc, key) => {
    const filterKey = key as T;
    const selectedOptions = Object.keys(filters[filterKey]).filter(
      (optionKey) => filters[filterKey][optionKey]
    );

    return [...acc, { type: filterKey, value: selectedOptions }];
  }, []);
