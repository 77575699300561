import { FilterType, FormFiltersType } from 'types';
import { FILTER_QUERY_REGEXP } from '../const';

export const parseFiltersFromParams = <T extends string>(
  params: URLSearchParams
): { filters: Array<FilterType<T>>; formFilters?: FormFiltersType<T> } => {
  const filters: Array<FilterType<T>> = [];
  const formFilters: FormFiltersType<T> = {} as FormFiltersType<T>;

  params.forEach((value, key) => {
    const match = key.match(FILTER_QUERY_REGEXP);

    if (match) {
      const filterKey = match[1] as T;
      const parsedValue = value.split(',');

      filters.push({ type: filterKey, value: parsedValue });
      formFilters[filterKey] = parsedValue.reduce(
        (acc, val) => ({
          ...acc,
          [val]: true,
        }),
        {}
      );
    }
  });

  return {
    filters,
    formFilters: Object.keys(formFilters).length ? formFilters : undefined,
  };
};
