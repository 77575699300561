export const COLUMNS_SIZES = {
  mediaNameSize: 256,
  ownerSize: 180,
  default: 160,
} as const;

export const ALL_COLUMNS = {
  id: 'id',
  owner: 'owner',
  hits: 'statisticInfo.hits',
  hosts: 'statisticInfo.hosts',
  registrations: 'statisticInfo.registrations',
  firstDeposits: 'statisticInfo.firstDeposits',
  depositsAll: 'statisticInfo.depositsAll',
  withdrawalsAll: 'statisticInfo.withdrawalsAll',
  depositsFee: 'statisticInfo.depositsFee',
  withdrawalsFee: 'statisticInfo.withdrawalsFee',
  adminFee: 'statisticInfo.adminFee',
  reward: 'statisticInfo.reward',
  averageDeposit: 'statisticInfo.averageDeposit',
  redepositsAll: 'statisticInfo.redepositsAll',
  conversionHostsToRegPercent: 'statisticInfo.conversionHostsToRegPercent',
  conversionRegToDepPercent: 'statisticInfo.conversionRegToDepPercent',
  numberOfRedeposits: 'statisticInfo.numberOfRedeposits',
};

export const initialVisibleColumns: Array<string> = [
  ALL_COLUMNS.id,
  ALL_COLUMNS.owner,
  ALL_COLUMNS.hits,
  ALL_COLUMNS.hosts,
  ALL_COLUMNS.registrations,
  ALL_COLUMNS.firstDeposits,
  ALL_COLUMNS.depositsAll,
  ALL_COLUMNS.withdrawalsAll,
  ALL_COLUMNS.depositsFee,
  ALL_COLUMNS.withdrawalsFee,
  ALL_COLUMNS.adminFee,
  ALL_COLUMNS.reward,
];
