import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { useSwitchPartnerCurrentCurrencyAccountMutation } from './useSwitchPartnerCurrentCurrencyAccountMutation';
import { useSwitchCompanyCurrentCurrencyAccountMutation } from './useSwitchCompanyCurrentCurrencyAccountMutation';

export const useSwitchCurrentCurrencyAccount = () => {
  const { t } = useTranslation('common');

  const { userTypeName, loading: userTypeNameLoading } =
    useGetUserTypeNameQuery({
      onError: () => {
        toast.error(t('errors.commonError'));
      },
    });

  const partnerResult = useSwitchPartnerCurrentCurrencyAccountMutation();
  const companyResult = useSwitchCompanyCurrentCurrencyAccountMutation();

  const result =
    userTypeName === UserTypeName.Partner ? partnerResult : companyResult;

  return { ...result, loading: userTypeNameLoading || result.loading };
};
