import { FC } from 'react';
import { Icon, IconSize } from 'ui/Icon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { ReactComponent as Flag } from 'assets/icons/line/Flag.svg';
import styles from './LoadMoreStatus.module.scss';

interface LoadingStatusProps {
  isLoading: boolean;
  hasMoreData: boolean;
}

export const LoadMoreStatus: FC<LoadingStatusProps> = ({
  isLoading,
  hasMoreData,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles.statusContainer}>
        <Icon
          iconClassName={styles.loadingIcon}
          iconComponent={LoadingIcon}
          hasDefaultColor={false}
          size={IconSize.Small}
          spin
        />
        <span className={styles.text}>{t('progress.loading')}</span>
      </div>
    );
  }

  if (!hasMoreData) {
    return (
      <div className={styles.statusContainer}>
        <Icon
          iconClassName={styles.flagIcon}
          iconComponent={Flag}
          hasDefaultColor={false}
          size={IconSize.Small}
        />
        <span className={styles.text}>{t('progress.allDataLoaded')}</span>
      </div>
    );
  }

  return null;
};
