import { useCallback, useState } from 'react';
import { Navbar } from 'components/Navbar';
import { ReactComponent as DocumentsIcon } from 'assets/icons/colors/Documents.svg';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'components/TopBar';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { ScrollToTopButton } from 'components/ScrollToTopButton';
import { NavigationBlock } from './components/NavigationBlock';
import { ContentBlock } from './components/ContentBlock';
import styles from './TermsOfUse.module.scss';

export const TermsOfUse = () => {
  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  const { t } = useTranslation('termsOfUse');
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    isMobile ? document.documentElement : null
  );
  const [currentSectionTitle, setCurrentSectionTitle] = useState<string>('');

  const containerRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null && !isMobile) {
        setContainerElement(node);
      }
    },
    [isMobile]
  );

  const handleCurrentSectionTitleChange = (title: string) => {
    if (!title || currentSectionTitle === title) {
      return;
    }

    setCurrentSectionTitle(title);
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.layout}>
        <div className={styles.body}>
          <TopBar title={t('content.main.title')} icon={DocumentsIcon} />
          <div className={styles.contentWrapper} ref={containerRef}>
            <NavigationBlock currentSectionTitle={currentSectionTitle} />
            <ContentBlock
              setCurrentSectionTitle={handleCurrentSectionTitleChange}
            />
          </div>
          {containerElement && (
            <div className={styles.scrollButtonWrapper}>
              <ScrollToTopButton scrollableElement={containerElement} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
