import classNames from 'classnames';
import { Column, flexRender, HeaderGroup } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import { getGridTemplateColumns } from 'ui/Table/helpers';
import { TableColumnCell } from '../TableColumnCell';
import styles from './TableHeader.module.scss';

interface TableHeaderProps<TData> {
  headerGroups: Array<HeaderGroup<TData>>;
  columns: Array<Column<TData>>;
  canSomeRowsExpand: boolean;
}

export const TableHeader = <TData,>({
  headerGroups,
  columns,
  canSomeRowsExpand,
}: TableHeaderProps<TData>) => {
  const gridTemplateColumns = getGridTemplateColumns(columns);

  return (
    <div className={styles.container} role="rowgroup">
      {headerGroups.map((headerGroup) => {
        let nonPinnedColumnsWidth = 0;

        return (
          <div
            key={headerGroup.id}
            className={classNames(styles.groupContainer, {
              [styles.withExpand]: canSomeRowsExpand,
            })}
            role="row"
            style={{
              gridTemplateColumns,
            }}
          >
            {headerGroup.headers.map((header) => {
              const { enablePinning, meta } = header.column.columnDef;

              const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
                column: header.column,
                nonPinnedColumnsWidth,
              });

              nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

              return (
                <TableColumnCell
                  key={header.id}
                  isPinned={enablePinning}
                  left={left}
                  align={meta?.align}
                  className={classNames({
                    [styles.pinned]: enablePinning,
                  })}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableColumnCell>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
