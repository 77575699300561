import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { useGetReferralLinkMediaItems } from '../../../../../queries/generated/GetReferralLinkMediaItems';
import { ReferralLinkMediaItemBasicData } from '../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';

const MEDIA_ITEMS_PER_PAGE = 10;

interface UseGetReferralLinkMediaItemsQueryParams {
  searchQuery?: string;
}

export const useGetReferralLinkMediaItemsQuery = ({
  searchQuery,
}: UseGetReferralLinkMediaItemsQueryParams) => {
  const { user } = useAuth();

  const { data, fetchMore, error, loading, isSliced } =
    useGetReferralLinkMediaItems({
      variables: {
        userId: user.id,
        first: MEDIA_ITEMS_PER_PAGE,
        ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
    });

  const referralLinkMediaItemsConnection = data?.user.referralLinkMediaItems;

  const referralLinkMediaItems:
    | Array<ReferralLinkMediaItemBasicData>
    | undefined = useMemo(
    () => referralLinkMediaItemsConnection?.edges.map((item) => item.node),
    [referralLinkMediaItemsConnection?.edges]
  );

  const pageInfo = referralLinkMediaItemsConnection?.pageInfo;

  return {
    referralLinkMediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error,
    loading,
  };
};
