import { Resources } from 'i18n/types';

import commonEN from 'locales/en/common.json';
import mediaEN from 'locales/en/media.json';
import notFoundEN from 'locales/en/notFound.json';
import paymentsEN from 'locales/en/payments.json';
import tariffsEN from 'locales/en/tariffs.json';
import companyPartnersEN from 'locales/en/companyPartners.json';
import profileEN from 'locales/en/profile.json';
import statisticEN from 'locales/en/statistic.json';
import authEN from 'locales/en/auth.json';
import termsOfUseEN from 'locales/en/termsOfUse.json';

export const resourcesEn = {
  common: commonEN,
  media: mediaEN,
  notFound: notFoundEN,
  payments: paymentsEN,
  tariffs: tariffsEN,
  companyPartners: companyPartnersEN,
  profile: profileEN,
  statistic: statisticEN,
  auth: authEN,
  termsOfUse: termsOfUseEN,
} satisfies Partial<Resources>;
