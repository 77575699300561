import { useAuth } from 'components/auth';
import { useGetUserTypeName } from 'queries/generated/GetUserTypeName';

interface UseGetUserTypeNameQueryParams {
  onError?: () => void;
}

export const useGetUserTypeNameQuery = ({
  onError,
}: UseGetUserTypeNameQueryParams = {}) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetUserTypeName({
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-first',
    onError,
  });

  return {
    userTypeName: data?.user.__typename,
    loading,
    error,
  };
};

export type UseGetUserTypeNameQueryResult = ReturnType<
  typeof useGetUserTypeNameQuery
>;
