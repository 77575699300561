import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListButton } from 'ui/ListButton';
import { ReactComponent as TermsOfUseIcon } from 'assets/icons/solid/TermsOfUse.svg';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { useTranslationUserType } from 'hooks/useTranslationUserType';

export const InfoBlock: FC = () => {
  const { t } = useTranslation('profile');
  const { createLocalizedPath } = useCreateLocalizedPath();

  const translationUserType = useTranslationUserType();

  return (
    <>
      <SubHeaderBlock>
        <h2>
          {t(`${translationUserType}.content.details.documents.titleDocuments`)}
        </h2>
      </SubHeaderBlock>
      <ListButton
        as="a"
        to={createLocalizedPath(AppRoute.Lang.TermsOfUse)}
        icon={TermsOfUseIcon}
        title={t(`${translationUserType}.content.details.documents.termsOfUse`)}
        target="_blank"
      />
    </>
  );
};
