import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterType, FormFiltersType } from 'types';
import {
  clearFiltersFromParamsObject,
  parseFiltersFromParams,
  serializeFilters,
} from './helpers';
import { FILTER_QUERY_REGEXP } from './const';

export const useFiltersHandlers = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasFilters = useMemo(
    () =>
      Array.from(searchParams.keys()).some((key) =>
        key.match(FILTER_QUERY_REGEXP)
      ),
    [searchParams]
  );

  const clearFiltersParams = () => {
    setSearchParams(
      () => {
        const params = new URLSearchParams(window.location.search);

        clearFiltersFromParamsObject(params);

        return params;
      },
      { replace: true }
    );
  };

  const updateUrlFilterParams = useCallback(
    <T>(filters: Array<FilterType<T>>) => {
      setSearchParams(
        () => {
          const params = new URLSearchParams(window.location.search);

          clearFiltersFromParamsObject(params);

          filters.forEach((filter) => {
            const values = filter.value;

            if (values.length) {
              params.set(`filters[${filter.type}]`, values.join(','));
            }
          });

          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const handleFiltersChange = <T extends string>(
    filters: FormFiltersType<T>
  ) => {
    const parsedFilters = serializeFilters(filters);

    updateUrlFilterParams<T>(parsedFilters);
  };

  const parseUrlFilters = <T extends string>() =>
    parseFiltersFromParams<T>(searchParams);

  return {
    onFiltersChange: handleFiltersChange,
    parseUrlFilters,
    clearFiltersParams,
    hasFilters,
  };
};
