import { FILTER_QUERY_REGEXP } from '../const';

export const clearFiltersFromParamsObject = (params: URLSearchParams) => {
  Array.from(params.keys()).forEach((key) => {
    const match = key.match(FILTER_QUERY_REGEXP);

    if (match) {
      const filterKey = match[1];

      params.delete(`filters[${filterKey}]`);
    }
  });
};
