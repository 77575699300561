import { useAuth } from 'components/auth';
import { NonNullableDateRange } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import { useGetRevShareStatisticDetailed } from '../query/generated/GetRevShareStatisticDetailed';

const { DateScalar } = DateFormat;

interface UseGetRevShareStatisticDetailedQueryParams {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
  searchQuery?: string;
}

export const useGetRevShareStatisticDetailedQuery = ({
  revShareMediaItemId,
  nonNullableDateRange,
  searchQuery,
}: UseGetRevShareStatisticDetailedQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, fetchMore, isSliced, loading, error } =
    useGetRevShareStatisticDetailed({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        userId: user.id,
        revShareMediaItemId,
        first: STATISTIC_ITEMS_PER_PAGE,
        ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
      cacheFirstOnPop: true,
    });

  const revShareStatisticDetailed = data?.user.referralLinkMediaItem;

  const pageInfo =
    revShareStatisticDetailed?.revShareStatistic.statisticItems.pageInfo;

  return {
    revShareStatisticDetailed,
    fetchMore,
    pageInfo,
    loading,
    isSliced,
    error,
  };
};
