import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, NonNullableDateRange, FilterType } from 'types';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { useGetRevShareStatisticTotalQuery } from './useGetPartnerRevShareStatisticTotalQuery';
import { PartnerRevShareStatisticInfoData } from '../queries/fragments/generated/PartnerRevShareStatisticInfoData';

type GetSummaryRowData = (
  info: PartnerRevShareStatisticInfoData
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = ({
  hits,
  hosts,
  registrations,
  firstDeposits,
  depositsAll,
  withdrawalsAll,
  depositsFee,
  withdrawalsFee,
  adminFee,
  reward,
  averageDeposit,
  redepositsAll,
  conversionHostsToRegPercent,
  conversionRegToDepPercent,
  numberOfRedeposits,
}) => {
  const result: Array<ReactNode> = [
    hits,
    hosts,
    registrations,
    firstDeposits,
    getMoneyAmount(depositsAll),
    getMoneyAmount(withdrawalsAll),
    getMoneyAmount(depositsFee),
    getMoneyAmount(withdrawalsFee),
    getMoneyAmount(adminFee),
    getMoneyAmount(reward),
    getMoneyAmount(averageDeposit),
    getMoneyAmount(redepositsAll),
    `${conversionHostsToRegPercent}%`,
    `${conversionRegToDepPercent}%`,
    numberOfRedeposits,
  ];

  return result;
};

interface UseGeneratePartnerRevShareStatisticSummaryRowParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  currency: Currency;
  searchQuery?: string;
}

export const useGeneratePartnerRevShareStatisticSummaryRow = ({
  nonNullableDateRange,
  currency,
  searchQuery,
  filters,
}: UseGeneratePartnerRevShareStatisticSummaryRowParams) => {
  const { t } = useTranslation('statistic');

  const { totalStatistic, error, loading } = useGetRevShareStatisticTotalQuery({
    nonNullableDateRange,
    currency,
    searchQuery,
    filters,
  });

  const summaryRowData = totalStatistic
    ? [
        t('partner.content.dataTable.revShare.allMedia'),
        ...getSummaryRowData(totalStatistic),
      ]
    : undefined;

  return { summaryRowData, loading, error };
};
