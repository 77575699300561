import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CompanyRevShareStatisticInfoData } from '../fragments/generated/CompanyRevShareStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyRevShareStatisticTotalVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  end: SchemaTypes.Scalars['Date']['input'];
  filters:
    | Array<SchemaTypes.CompanyRevShareStatisticFilterInput>
    | SchemaTypes.CompanyRevShareStatisticFilterInput;
  referralLinkSearch?: SchemaTypes.InputMaybe<
    SchemaTypes.Scalars['String']['input']
  >;
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyRevShareStatisticTotal = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        revShareStatistic: {
          __typename: 'CompanyRevShareStatistic';
          total: {
            __typename: 'CompanyRevShareStatisticInfo';
            conversionHostsToRegPercent: number;
            conversionRegToDepPercent: number;
            firstDeposits: number;
            hits: number;
            hosts: number;
            numberOfRedeposits: number;
            registrations: number;
            adminFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyRevShareStatisticTotalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyRevShareStatisticTotal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Currency' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'CompanyRevShareStatisticFilterInput',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revShareStatistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filters' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'referralLinkSearch',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'referralLinkSearch',
                                    },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CompanyRevShareStatisticInfoData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompanyRevShareStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyRevShareStatisticTotal__
 *
 * To run a query within a React component, call `useGetCompanyRevShareStatisticTotal` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRevShareStatisticTotal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRevShareStatisticTotal({
 *   variables: {
 *      currency: // value for 'currency'
 *      end: // value for 'end'
 *      filters: // value for 'filters'
 *      referralLinkSearch: // value for 'referralLinkSearch'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyRevShareStatisticTotal(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  > &
    (
      | { variables: GetCompanyRevShareStatisticTotalVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  >(GetCompanyRevShareStatisticTotalDocument, options);
}
export function useGetCompanyRevShareStatisticTotalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  >(GetCompanyRevShareStatisticTotalDocument, options);
}
export function useGetCompanyRevShareStatisticTotalSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyRevShareStatisticTotal,
    GetCompanyRevShareStatisticTotalVariables
  >(GetCompanyRevShareStatisticTotalDocument, options);
}
export type GetCompanyRevShareStatisticTotalHookResult = ReturnType<
  typeof useGetCompanyRevShareStatisticTotal
>;
export type GetCompanyRevShareStatisticTotalLazyQueryHookResult = ReturnType<
  typeof useGetCompanyRevShareStatisticTotalLazyQuery
>;
export type GetCompanyRevShareStatisticTotalSuspenseQueryHookResult =
  ReturnType<typeof useGetCompanyRevShareStatisticTotalSuspenseQuery>;
export type GetCompanyRevShareStatisticTotalQueryResult = Apollo.QueryResult<
  GetCompanyRevShareStatisticTotal,
  GetCompanyRevShareStatisticTotalVariables
>;
