import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticInfoData } from './RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type RevShareReferralLinkStatisticTotalData = {
  __typename: 'ReferralLinkMediaItem';
  id: string;
  revShareStatistic: {
    __typename: 'RevShareReferralLinkStatistic';
    total: {
      __typename: 'RevShareReferralLinkStatisticInfo';
      numberOfRedeposits: number;
      wasFD: boolean;
      adminFee: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      averageDeposit: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      depositsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      depositsFee: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      redepositsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      withdrawalsAll: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
      withdrawalsFee: {
        __typename: 'MoneyAmount';
        currency: CommonType.Currency;
        value: number;
      };
    };
  };
};

export const RevShareReferralLinkStatisticTotalData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RevShareReferralLinkStatisticTotalData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revShareStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'end' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'referralNameSearch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referralNameSearch' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RevShareReferralLinkStatisticInfoData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
