import { useGetAvailableCurrenciesForStatistic } from '../queries/generated/GetAvailableCurrenciesForStatistic';

export const useGetAvailableCurrenciesQueryForStatistic = () => {
  const { data, loading, error } = useGetAvailableCurrenciesForStatistic({
    fetchPolicy: 'cache-first',
  });

  return {
    availableCurrenciesForStatistic: data?.availableCurrenciesForStatistic,
    loading,
    error,
  };
};
