import { FC, useEffect, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';
import { ProfileFormHeader } from 'pages/Profile/components/ProfileDataBlock/components/ProfileFormHeader';
import { useTranslation } from 'react-i18next';
import { FormBlockWrapper, Input, SelectAndInput } from 'ui/formItems';
import { DatePickerWithInput } from 'ui/DatePickerWithInput';
import { useContactTypeOptions } from 'hooks/useContactTypeOptions';
import {
  IndentBetweenRowsSize,
  MarginBottomSize,
} from 'ui/formItems/components/FormBlockWrapper/const';
import { FieldName } from '../../const';
import { PartnerIdentitiesValues } from '../../types';
import styles from './PartnerProfileFormContent.module.scss';
import { INPUT_ACCEPTED_CHARS_REGEXP, INPUT_MAX_LENGTH } from './const';

interface PartnerProfileFormContentProps {
  loading: boolean;
  onDirtyChange: (dirty: boolean) => void;
}

export const PartnerProfileFormContent: FC<PartnerProfileFormContentProps> = ({
  loading,
  onDirtyChange,
}) => {
  const { t } = useTranslation('profile');
  const { values, isValid, dirty, errors, setFieldValue } =
    useFormikContext<PartnerIdentitiesValues>();

  const contactTypeOptions = useContactTypeOptions();

  const maxDateForDatePicker = useMemo(() => new Date(), []);

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Form>
      <ProfileFormHeader isDisabled={!isValid || !dirty} isLoading={loading} />
      <FormBlockWrapper
        indentBetweenRowSize={IndentBetweenRowsSize.Large}
        marginBottomSize={MarginBottomSize.Large}
      >
        <div className={styles.identitiesContainer}>
          <Input
            name={FieldName.FirstName}
            label={t(
              'partner.content.details.personalDetails.input.label.firstName'
            )}
            autoComplete="given-name"
            acceptedCharsRegExp={INPUT_ACCEPTED_CHARS_REGEXP}
            maxLength={INPUT_MAX_LENGTH}
          />
          <Input
            name={FieldName.LastName}
            label={t(
              'partner.content.details.personalDetails.input.label.lastName'
            )}
            autoComplete="family-name"
            acceptedCharsRegExp={INPUT_ACCEPTED_CHARS_REGEXP}
            maxLength={INPUT_MAX_LENGTH}
          />
        </div>
        <DatePickerWithInput
          label={t(
            'partner.content.details.personalDetails.input.label.dateOfBirth'
          )}
          name={FieldName.Birthday}
          onChange={(value) => setFieldValue(FieldName.Birthday, value)}
          maxDate={maxDateForDatePicker}
        />
        <SelectAndInput
          selectProps={{
            name: FieldName.ContactType,
            label: t(
              'partner.content.details.personalDetails.input.label.contact'
            ),
            options: contactTypeOptions,
          }}
          inputProps={{
            name: FieldName.ContactValue,
            label: t(
              'partner.content.details.personalDetails.input.label.username'
            ),
            disabled: !values.contactType,
            maxLength: INPUT_MAX_LENGTH,
          }}
          isError={Boolean(errors.contactValue)}
        />
      </FormBlockWrapper>
    </Form>
  );
};
