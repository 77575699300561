import { Currency } from 'types';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { CURRENCY_PAGE_PARAM } from '../const';

export const extractCurrencyFromSearchParams = (
  params: URLSearchParams
): Currency | undefined => {
  const rawCurrency = params.get(CURRENCY_PAGE_PARAM);
  const currency = rawCurrency ? rawCurrency.toUpperCase() : undefined;

  if (!currency || !checkIsCurrency(currency)) {
    return undefined;
  }

  return currency;
};
