import { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { DropdownPosition } from 'ui/Dropdown';
import { Skeleton } from 'ui/Skeleton';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { CheckboxSize } from 'ui/formItems/components/Checkbox/const';
import { Checkbox } from 'ui/formItems/components/Checkbox';
import styles from './FilterPill.module.scss';

interface FilterOption {
  id: string;
  label: string;
}

interface FilterPillProps {
  title: string;
  name: string;
  options: Array<FilterOption>;
  loading: boolean;
  onChange: () => void;
  loadMore?: () => void;
  disabled?: boolean;
}

export const FilterPill: FC<FilterPillProps> = ({
  title,
  name,
  options,
  loading,
  onChange,
  loadMore,
  disabled,
}) => {
  const [field, , setter] = useField(`filters.${name}`);
  const { handleSubmit } = useFormikContext();

  const loadTrigger = useInfiniteScroll(loadMore);

  const isAnyOptionSelected = Object.values(field.value ?? []).some(Boolean);

  const sortedOptions = options.toSorted((next, prev) => {
    const prevValue = field.value?.[prev.id];
    const nextValue = field.value?.[next.id];

    if (!prevValue && nextValue) {
      return -1;
    }

    return 0;
  });

  const handleClean = () => {
    if (!field.value) return;

    const newValues = Object.keys(field.value).reduce<Record<string, boolean>>(
      (acc, key) => {
        acc[key] = false;

        return acc;
      },
      {}
    );

    setter.setValue(newValues);
    handleSubmit();
  };

  if (loading) {
    return <Skeleton width={121} height={32} />;
  }

  if (!sortedOptions.length) {
    return null;
  }

  return (
    <DropdownWithButton
      dropdownProps={{
        position: DropdownPosition.LeftBottom,
        title,
      }}
      buttonContent={title}
      buttonProps={{
        disabled,
        ...(isAnyOptionSelected && {
          iconConfig: {
            iconComponent: CloseIcon,
            onClick: handleClean,
          },
        }),
      }}
    >
      <div className={styles.container}>
        {sortedOptions.map((option) => (
          <Checkbox
            key={option.id}
            name={`filters.${name}.${option.id}`}
            label={option.label}
            onChange={onChange}
            checkboxSize={CheckboxSize.Small}
            className={styles.checkbox}
          />
        ))}
        {loadTrigger}
      </div>
    </DropdownWithButton>
  );
};
