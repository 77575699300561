import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { FilterType, NonNullableDateRange, UserTypeName } from 'types';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { DateFormat, formatDate } from 'utils/formatDate';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import { useGetCompanyCpaStatisticItems } from '../queries/generated/GetCompanyCpaStatisticItems';
import { CompanyCpaStatisticItemData } from '../queries/fragments/generated/CompanyCpaStatisticItemData';

const { DateScalar } = DateFormat;

interface UseGetCompanyCpaStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  searchQuery?: string;
}

export const useGetCompanyCpaStatisticItemsQuery = ({
  nonNullableDateRange,
  searchQuery,
  filters,
}: UseGetCompanyCpaStatisticItemsQueryParams) => {
  const { user } = useAuth();

  const [start, end] = nonNullableDateRange;

  const { data, fetchMore, loading, isSliced, error } =
    useGetCompanyCpaStatisticItems({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        first: STATISTIC_ITEMS_PER_PAGE,
        userId: user.id,
        filters,
        ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
      cacheFirstOnPop: true,
    });

  const statisticItemsConnection =
    data?.user.__typename === UserTypeName.Company
      ? data.user.cpaStatistic.statisticItems
      : undefined;

  const statisticItems: Array<CompanyCpaStatisticItemData> | undefined =
    useMemo(
      () => statisticItemsConnection?.edges.map((item) => item.node),
      [statisticItemsConnection?.edges]
    );

  const pageInfo = statisticItemsConnection?.pageInfo;

  return { statisticItems, fetchMore, pageInfo, loading, isSliced, error };
};
