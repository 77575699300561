import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterPill } from 'components/FilterPill';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { usePartnerCpaStatisticFilters } from './hooks/usePartnerCpaStatisticFilters';
import styles from './PartnerCpaStatisticTableFilters.module.scss';

interface PartnerCpaStatisticTableFiltersProps {
  onChange: () => void;
  onClear: () => void;
}

export const PartnerCpaStatisticTableFilters: FC<
  PartnerCpaStatisticTableFiltersProps
> = ({ onChange, onClear }) => {
  const { filters } = usePartnerCpaStatisticFilters();
  const [searchParams] = useSearchParams();

  const { clearFiltersParams, hasFilters } = useFiltersHandlers();

  const isDisabled = searchParams.has('search');

  const handleClear = () => {
    onClear();
    clearFiltersParams();
  };

  if (!filters.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {hasFilters && (
        <Button
          onClick={handleClear}
          size={ButtonSize.Small}
          theme={ButtonTheme.Grey}
          iconConfig={{
            iconComponent: CloseIcon,
          }}
        />
      )}
      {filters.map(({ type, title, options, loading, loadMore }) => (
        <FilterPill
          key={type}
          name={type}
          title={title}
          options={options}
          loading={loading}
          disabled={isDisabled}
          onChange={onChange}
          loadMore={loadMore}
        />
      ))}
    </div>
  );
};
