import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'ui/Tabs';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useSearchParams } from 'react-router-dom';
import { ReferralLinkMediaTable } from './components/ReferralLinkMediaTable';
import { PromoCodeMediaTable } from './components/PromoCodeMediaTable';
import styles from './MediaDataBlock.module.scss';

export const MediaDataBlock: FC = () => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();
  const [, setSearchParams] = useSearchParams();

  const handleTabChange = () => {
    setSearchParams(
      () => {
        const params = new URLSearchParams(window.location.search);

        Array.from(params.keys()).forEach((key) => {
          params.delete(key);
        });

        return params;
      },
      { replace: true }
    );
  };

  const tabs = [
    {
      title: t(`${translationUserType}.action.tabMenu.links`),
      content: <ReferralLinkMediaTable />,
    },
    {
      title: t(`${translationUserType}.action.tabMenu.promoCodes`),
      content: <PromoCodeMediaTable />,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      tabsControlsClass={styles.tabs}
      onTabChange={handleTabChange}
    />
  );
};
