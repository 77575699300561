import { FC } from 'react';
import { AuthorizationData } from '../AuthorizationData';
import { GetUserIdentities } from '../../queries/generated/GetUserIdentities';
import { CompanyProfileForm } from './components/CompanyProfileForm';
import { InfoBlock } from '../InfoBlock';

interface CompanyDataBlockContentProps {
  companyIdentities: Extract<
    GetUserIdentities['user'],
    { __typename: 'Company' }
  >;
  setShouldShowConfirmationModal: (shouldShow: boolean) => void;
}

export const CompanyDataBlockContent: FC<CompanyDataBlockContentProps> = ({
  companyIdentities,
  setShouldShowConfirmationModal,
}) => {
  const { name, email, passwordUpdatedAt, website, contact } =
    companyIdentities;

  const handleDirtyChange = (dirty: boolean) => {
    setShouldShowConfirmationModal(dirty);
  };

  return (
    <>
      <CompanyProfileForm
        companyName={name}
        webSite={website}
        contact={contact}
        onDirtyChange={handleDirtyChange}
      />
      <AuthorizationData email={email} passwordUpdatedAt={passwordUpdatedAt} />
      <InfoBlock />
    </>
  );
};
