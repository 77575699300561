import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { TariffTypeName, UserTypeName } from 'types';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { useGetCompanyName } from 'queries/generated/GetCompanyName';
import { useLoadMore } from 'hooks/useLoadMore';
import { useMemo } from 'react';
import { getTariffsOptions } from 'helpers';
import { useGetFilterCompanyPartners } from 'queries/generated/GetFilterCompanyPartners';
import { getCompanyPartnersOptions } from 'helpers/getCompanyPartnersOptions';
import { CompanyPartnersFiltersData } from 'queries/fragments/generated/CompanyPartnersFiltersData';
import { COMPANY_PARTNERS_ITEMS_PER_PAGE } from '../const';

interface CompanyRevShareStatisticFilterOption {
  id: string;
  label: string;
}

interface CompanyRevShareStatisticFilter {
  type: CompanyStatisticFilterType;
  title: string;
  options: Array<CompanyRevShareStatisticFilterOption>;
  loading: boolean;
  loadMore?: () => void;
}

export const useCompanyRevShareStatisticFilters = () => {
  const { t } = useTranslation('statistic');
  const { user } = useAuth();

  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });

  const { data: userIdentities } = useGetCompanyName({
    variables: {
      id: user.id,
    },
  });

  const {
    data: companyPartnersData,
    fetchMore: companyPartnersFetchMore,
    error: companyPartnersError,
    loading: companyPartnersLoading,
    networkStatus: companyPartnersNetworkStatus,
  } = useGetFilterCompanyPartners({
    variables: {
      userId: user.id,
      first: COMPANY_PARTNERS_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const error = tariffsError || companyPartnersError;

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
    currentTypeName: TariffTypeName.RevShareTariff,
  });

  const companyPartnersConnection =
    companyPartnersData?.user.__typename === UserTypeName.Company
      ? companyPartnersData.user.companyPartners
      : undefined;

  const companyPartnersLoadMore = useLoadMore(
    companyPartnersFetchMore,
    companyPartnersConnection?.pageInfo
  );

  const companyPartnersItems: Array<CompanyPartnersFiltersData> | undefined =
    useMemo(
      () => companyPartnersConnection?.edges.map((item) => item.node),
      [companyPartnersConnection?.edges]
    );

  const companyPartnersOptions = getCompanyPartnersOptions({
    userIdentities,
    companyPartnersItems,
  });

  const filters: Array<CompanyRevShareStatisticFilter> = [
    {
      type: CompanyStatisticFilterType.Tariff,
      title: t('company.action.filters.filter.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
    {
      type: CompanyStatisticFilterType.MediaItemOwner,
      title: t('company.action.filters.filter.owner'),
      options: companyPartnersOptions,
      loadMore: companyPartnersLoadMore,
      loading:
        companyPartnersLoading &&
        companyPartnersNetworkStatus === NetworkStatus.loading,
    },
  ];

  return {
    filters,
    error,
  };
};
