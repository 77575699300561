import { Column } from '@tanstack/react-table';

export const getGridTemplateColumns = <TData>(
  columns: Array<Column<TData>>
) => {
  let hasMinSize = false;

  return columns.reduce((currentColumnTemplate, column, index) => {
    const { size, minSize } = column.columnDef;

    if (minSize) {
      if (!hasMinSize) {
        hasMinSize = true;
      }

      return `${currentColumnTemplate}minmax(${minSize}px, 1fr) `;
    }

    if (index === columns.length - 1 && !hasMinSize) {
      return `${currentColumnTemplate}minmax(${size}px, 1fr) `;
    }

    return `${currentColumnTemplate}${size}px `;
  }, '');
};
