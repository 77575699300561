import { LocalError } from 'const';
import { blurActiveElement } from 'helpers/blurActiveElement';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types';
import { toast } from 'ui/toast';
import { useCurrencyParams } from 'hooks/useCurrencyParams';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';

export interface UseStatisticCurrencyResult {
  updateCurrency: (currency: Currency) => void;
  loading: boolean;
  currency?: Currency;
}

export const useStatisticCurrency = (): UseStatisticCurrencyResult => {
  const { t } = useTranslation('common');

  const defaultCurrency = Currency.USD;

  const {
    currency,
    setCurrency,
    loading: paramLoading,
  } = useCurrencyParams({
    defaultCurrency,
  });

  const updateCurrency = (newCurrency: Currency) => {
    if (!checkIsCurrency(newCurrency)) {
      toast.error({
        header: t('errors.commonError'),
        text: LocalError.NullableCurrency,
      });

      return;
    }

    blurActiveElement();
    setCurrency(newCurrency);
  };

  return {
    currency,
    updateCurrency,
    loading: paramLoading,
  };
};
