import logoSrc from 'assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { DropdownPosition } from 'ui/Dropdown';
import { useLanguageDropdownButton } from 'hooks/useLanguageDropdownButton';
import { NavbarLanguageDropdownButton } from './components/NavbarLanguageDropdownButton';
import styles from './Navbar.module.scss';

export const Navbar = () => {
  const { t } = useTranslation();
  const { onOpenChange, isActive } = useLanguageDropdownButton();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={logoSrc}
          className={styles.img}
          data-testid="top-img"
          alt={t('logoAlt')}
        />
        <LanguageDropdown
          trigger={<NavbarLanguageDropdownButton isActive={isActive} />}
          withStyleMaxContent={false}
          onOpenChange={onOpenChange}
          position={DropdownPosition.RightBottom}
        />
      </div>
    </div>
  );
};
