import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useChangeCompanyReferralLinkMediaItemName } from '../queries/generated/ChangeCompanyReferralLinkMediaItemName';
import {
  GetReferralLinkMediaItem,
  GetReferralLinkMediaItemDocument,
} from '../../../../../queries/generated/GetReferralLinkMediaItem';

export const useChangeCompanyReferralLinkMediaItemNameMutation = () => {
  const client = useApolloClient();
  const { user } = useAuth();
  const { t } = useTranslation('common');

  const [changeCompanyReferralLinkMediaItemNameMutation, { loading }] =
    useChangeCompanyReferralLinkMediaItemName();

  const handleError = useCallback(() => {
    toast.error(t('errors.commonError'));
  }, [t]);

  const changeReferralLinkMediaItemName = useCallback(
    (referralLinkMediaItemId: string, name: string) => {
      if (!referralLinkMediaItemId || !name) {
        return undefined;
      }

      return changeCompanyReferralLinkMediaItemNameMutation({
        variables: {
          input: {
            companyId: user.id,
            referralLinkMediaItemId,
            name,
          },
        },
        onError: handleError,
        optimisticResponse: ({ input }, { IGNORE }) => {
          const currentReferralLinkMediaItem =
            client.readQuery<GetReferralLinkMediaItem>({
              query: GetReferralLinkMediaItemDocument,
              variables: {
                userId: input.companyId,
                referralLinkMediaItemId: input.referralLinkMediaItemId,
              },
            })?.user.referralLinkMediaItem;

          if (!currentReferralLinkMediaItem) {
            return IGNORE;
          }

          return {
            __typename: 'Mutation',
            changeCompanyReferralLinkMediaItemName: {
              __typename: 'ChangeCompanyReferralLinkMediaItemNamePayload',
              referralLinkMediaItem: {
                ...currentReferralLinkMediaItem,
                name: input.name,
              },
            },
          };
        },
      });
    },
    [
      changeCompanyReferralLinkMediaItemNameMutation,
      client,
      handleError,
      user.id,
    ]
  );

  return {
    changeReferralLinkMediaItemName,
    loading,
  };
};
