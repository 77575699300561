import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import {
  TariffTypeName,
  UserTypeName,
  WithdrawalInvoiceTypeName,
  WithdrawalMethodTypeName,
} from 'types';
import { useCombinedLink } from './useCombinedLink';

const isProd = process.env.NODE_ENV === 'production';

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const link = useCombinedLink();

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        UserInterface: Object.values(UserTypeName),
        TariffInterface: Object.values(TariffTypeName),
        WithdrawalInvoiceInterface: Object.values(WithdrawalInvoiceTypeName),
        WithdrawalMethodInterface: Object.values(WithdrawalMethodTypeName),
      },
      typePolicies: {
        Partner: {
          fields: {
            referralLinkMediaItems: relayStylePagination([
              'referralLinkSearch',
            ]),
            revShareStatistic: {
              merge: (existing, incoming, { args }) => {
                if (!args || args.currency !== incoming.currency) {
                  return incoming;
                }

                return [...existing, ...incoming];
              },
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            rewardHistory: relayStylePagination(),
            withdrawalInvoices: relayStylePagination(),
            promoCodeMediaItems: relayStylePagination(),
          },
        },
        PartnerRevShareStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'currency',
              'referralLinkSearch',
              'filters',
            ]),
          },
        },
        CompanyRevShareStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'currency',
              'referralLinkSearch',
              'filters',
            ]),
          },
        },
        PartnerCpaStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralLinkSearch',
              'filters',
            ]),
          },
        },
        CompanyCpaStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralLinkSearch',
              'filters',
            ]),
          },
        },
        Company: {
          fields: {
            referralLinkMediaItems: relayStylePagination([
              'referralLinkSearch',
            ]),
            companyPartners: relayStylePagination(),
            revShareStatistic: {
              merge: (existing, incoming, { args }) => {
                if (!args || args.currency !== incoming.currency) {
                  return incoming;
                }

                return [...existing, ...incoming];
              },
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            rewardHistory: relayStylePagination(),
            withdrawalInvoices: relayStylePagination(),
            promoCodeMediaItems: relayStylePagination(),
          },
        },
        ReferralLinkMediaItem: {
          fields: {
            postbackInfo: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            revShareStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
          },
        },
        CpaReferralLinkStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralNameSearch',
            ]),
          },
        },
        RevShareReferralLinkStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralNameSearch',
            ]),
          },
        },
      },
    }),
    connectToDevTools: !isProd,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: (lastFetchPolicy) => {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return 'cache-first';
          }

          return lastFetchPolicy;
        },
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
