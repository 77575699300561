import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { mapBooleanToString } from 'helpers';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { RevShareReferralLinkStatisticItemData } from '../query/fragments/generated/RevShareReferralLinkStatisticItemData';
import { COLUMNS_SIZES } from '../../../const';

export const useRevShareStatisticDetailedColumns = (): Array<
  ColumnDef<RevShareReferralLinkStatisticItemData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper =
    createColumnHelper<RevShareReferralLinkStatisticItemData>();

  const translationUserType = useTranslationUserType();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<RevShareReferralLinkStatisticItemData, any>> =
    [
      columnHelper.accessor('playerName', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.mediaElement`
        ),
        cell: ({ row }) => (
          <MultilineCellWithIcon
            title={row.original.playerName ?? '—'}
            subTitle={row.original.target}
          />
        ),
        enablePinning: true,
        size: COLUMNS_SIZES.mediaNameSize,
      }),
      columnHelper.accessor('statisticInfo.wasFD', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.wasFD`
        ),
        cell: ({ row }) => mapBooleanToString(row.original.statisticInfo.wasFD),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.depositsAll', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.depositsAll`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.depositsAll),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.withdrawalsAll', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.withdrawalsAll`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.withdrawalsAll),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.depositsFee', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.depositsFee`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.depositsFee),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.withdrawalsFee', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.withdrawalsFee`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.withdrawalsFee),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.adminFee', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.adminFee`
        ),
        cell: ({ row }) => getMoneyAmount(row.original.statisticInfo.adminFee),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.averageDeposit', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.averageDeposit`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.averageDeposit),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.redepositsAll', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.redepositsAll`
        ),
        cell: ({ row }) =>
          getMoneyAmount(row.original.statisticInfo.redepositsAll),
        minSize: COLUMNS_SIZES.default,
      }),
      columnHelper.accessor('statisticInfo.numberOfRedeposits', {
        header: t(
          `statistic:${translationUserType}.content.dataTable.revShare.numberOfRedeposits`
        ),
        cell: ({ row }) => row.original.statisticInfo.numberOfRedeposits,
        minSize: COLUMNS_SIZES.default,
      }),
    ];

  return columns;
};
