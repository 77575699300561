import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PartnerRevShareStatisticItemData } from '../fragments/generated/PartnerRevShareStatisticItemData';
import { PartnerRevShareStatisticInfoData } from '../fragments/generated/PartnerRevShareStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerRevShareStatisticItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  currency: SchemaTypes.Scalars['Currency']['input'];
  end: SchemaTypes.Scalars['Date']['input'];
  filters:
    | Array<SchemaTypes.PartnerRevShareStatisticFilterInput>
    | SchemaTypes.PartnerRevShareStatisticFilterInput;
  first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  referralLinkSearch?: SchemaTypes.InputMaybe<
    SchemaTypes.Scalars['String']['input']
  >;
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetPartnerRevShareStatisticItems = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | {
        __typename: 'Partner';
        id: string;
        revShareStatistic: {
          __typename: 'PartnerRevShareStatistic';
          statisticItems: {
            __typename: 'PartnerRevShareStatisticItemsConnection';
            edges: Array<{
              __typename: 'PartnerRevShareStatisticItemsConnectionEdge';
              cursor: string;
              node: {
                __typename: 'PartnerRevShareStatisticItem';
                id: string;
                link: string;
                name: string;
                statisticInfo: {
                  __typename: 'PartnerRevShareStatisticInfo';
                  conversionHostsToRegPercent: number;
                  conversionRegToDepPercent: number;
                  firstDeposits: number;
                  hits: number;
                  hosts: number;
                  numberOfRedeposits: number;
                  registrations: number;
                  adminFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  averageDeposit: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  redepositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  reward: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                };
              };
            }>;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
        };
      };
};

export const GetPartnerRevShareStatisticItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerRevShareStatisticItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Currency' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'PartnerRevShareStatisticFilterInput',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revShareStatistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statisticItems' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filters' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'referralLinkSearch',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'referralLinkSearch',
                                    },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cursor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'PartnerRevShareStatisticItemData',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PageInfoData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PartnerRevShareStatisticItemData.definitions,
    ...PartnerRevShareStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPartnerRevShareStatisticItems__
 *
 * To run a query within a React component, call `useGetPartnerRevShareStatisticItems` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerRevShareStatisticItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerRevShareStatisticItems({
 *   variables: {
 *      after: // value for 'after'
 *      currency: // value for 'currency'
 *      end: // value for 'end'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      referralLinkSearch: // value for 'referralLinkSearch'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartnerRevShareStatisticItems(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  > &
    (
      | { variables: GetPartnerRevShareStatisticItemsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  >(GetPartnerRevShareStatisticItemsDocument, options);
}
export function useGetPartnerRevShareStatisticItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  >(GetPartnerRevShareStatisticItemsDocument, options);
}
export function useGetPartnerRevShareStatisticItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPartnerRevShareStatisticItems,
    GetPartnerRevShareStatisticItemsVariables
  >(GetPartnerRevShareStatisticItemsDocument, options);
}
export type GetPartnerRevShareStatisticItemsHookResult = ReturnType<
  typeof useGetPartnerRevShareStatisticItems
>;
export type GetPartnerRevShareStatisticItemsLazyQueryHookResult = ReturnType<
  typeof useGetPartnerRevShareStatisticItemsLazyQuery
>;
export type GetPartnerRevShareStatisticItemsSuspenseQueryHookResult =
  ReturnType<typeof useGetPartnerRevShareStatisticItemsSuspenseQuery>;
export type GetPartnerRevShareStatisticItemsQueryResult = Apollo.QueryResult<
  GetPartnerRevShareStatisticItems,
  GetPartnerRevShareStatisticItemsVariables
>;
