import { FC } from 'react';
import { useLoadMore } from 'hooks/useLoadMore';
import { Table } from 'ui/Table';
import { useTranslation } from 'react-i18next';
import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useSearchParams } from 'react-router-dom';
import { useOpenReferralLinkModal } from '../../../../hooks/useOpenReferralLinkModal';
import { ReferralLinkMediaItemBasicData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useGetReferralLinkMediaItemsQuery } from './hooks/useGetReferralLinkMediaItemsQuery';
import { useReferralLinkMediaTableColumns } from './hooks/useReferralLinkMediaTableColumns';
import { ReferralLinkMediaTableSearch } from './components/ReferralLinkMediaTableSearch';

export const ReferralLinkMediaTable: FC = () => {
  const { openEditModal } = useOpenReferralLinkModal();
  const { t } = useTranslation('media');
  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    referralLinkMediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error: getReferralLinkMediaItemsError,
    loading: getReferralLinkMediaItemsLoading,
  } = useGetReferralLinkMediaItemsQuery({ searchQuery });

  const columns = useReferralLinkMediaTableColumns({
    isCompany: userTypeName === UserTypeName.Company,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const handleRowClick = ({ id }: ReferralLinkMediaItemBasicData) => {
    openEditModal(id);
  };

  const isLoading =
    (!referralLinkMediaItems && getReferralLinkMediaItemsLoading) ||
    getUserTypeLoading;
  const error = getUserTypeError || getReferralLinkMediaItemsError;

  const tableData = referralLinkMediaItems ?? [];

  return (
    <Table
      onRowClick={handleRowClick}
      columns={columns}
      data={tableData}
      error={error}
      emptyText={t('placeholder.links.noData')}
      hasSearchParams={!!searchQuery}
      isLoading={isLoading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      emptySearchStateTitle={t('placeholder.main.searchRequest.noDataHeader')}
      emptySearchStateDescription={t(
        'placeholder.main.searchRequest.noDataSubheader'
      )}
      errorStateTitle={t('placeholder.loadingError.loadingErrorHeader')}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheader'
      )}
      fetchMore={loadMore}
      searchComponent={
        <ReferralLinkMediaTableSearch initialValues={{ search: searchQuery }} />
      }
    />
  );
};
