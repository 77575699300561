import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaReferralLinkStatisticItemData } from './CpaReferralLinkStatisticItemData';
import { CpaReferralLinkStatisticInfoData } from './CpaReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type CpaReferralLinkStatisticConnectionData = {
  __typename: 'CpaReferralLinkStatisticConnection';
  edges: Array<{
    __typename: 'CpaReferralLinkStatisticEdge';
    cursor: string;
    node: {
      __typename: 'CpaReferralLinkStatisticItem';
      id: string;
      playerName?: string | null;
      referralStatus: SchemaTypes.ReferralStatus;
      target: string;
      statisticInfo: {
        __typename: 'CpaReferralLinkStatisticInfo';
        numberOfRedeposits: number;
        wasFD: boolean;
        averageDeposit: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        depositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        redepositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        withdrawalsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
      };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const CpaReferralLinkStatisticConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CpaReferralLinkStatisticConnectionData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CpaReferralLinkStatisticConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CpaReferralLinkStatisticItemData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
