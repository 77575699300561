import { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import { ReactComponent as CheckIcon } from 'assets/icons/line/DoneLarge.svg';
import { useEditingIdsContext } from 'contexts/EditingIdsContext';
import { Icon, IconSize } from 'ui/Icon';
import { Input } from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { MEDIA_ITEM_NAME_MAX_LENGTH } from './const';
import { FieldName } from '../../const';
import { MediaItemFormValues } from '../../types';
import styles from './MediaItemNameForm.module.scss';

interface MediaItemFormProps {
  referralLinkMediaItemId: string;
  loading: boolean;
}

export const MediaItemNameForm: FC<MediaItemFormProps> = ({
  referralLinkMediaItemId,
  loading,
}) => {
  const { dirty } = useFormikContext<MediaItemFormValues>();
  const { updateEditingElementsIds } = useEditingIdsContext();

  const translationUserType = useTranslationUserType();
  const { t } = useTranslation('media');

  const handleBlur = () => {
    if (dirty) {
      updateEditingElementsIds(referralLinkMediaItemId, dirty);
    }
  };

  return (
    <Form className={styles.form}>
      <div className={styles.inputWrapper}>
        <Input
          name={FieldName.Name}
          maxLength={MEDIA_ITEM_NAME_MAX_LENGTH}
          disabled={loading}
          wrapperClassName={styles.input}
          placeholder={t(
            `${translationUserType}.content.drawer.details.aboutMedia.label.mediaName`
          )}
          onBlur={handleBlur}
        />
      </div>

      <button type="submit" disabled={loading}>
        <Icon
          containerClassName={styles.icon}
          hasDefaultColor={false}
          iconComponent={CheckIcon}
          size={IconSize.Small}
        />
      </button>
    </Form>
  );
};
