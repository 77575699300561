import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  GetReferralLinkMediaItem,
  GetReferralLinkMediaItemDocument,
} from '../../../../../queries/generated/GetReferralLinkMediaItem';
import { useChangePartnerReferralLinkMediaItemName } from '../queries/generated/ChangePartnerReferralLinkMediaItemName';

export const useChangePartnerReferralLinkMediaItemNameMutation = () => {
  const client = useApolloClient();
  const { user } = useAuth();
  const { t } = useTranslation('common');

  const [changePartnerReferralLinkMediaItemNameMutation, { loading }] =
    useChangePartnerReferralLinkMediaItemName();

  const handleError = useCallback(() => {
    toast.error(t('errors.commonError'));
  }, [t]);

  const changeReferralLinkMediaItemName = useCallback(
    (referralLinkMediaItemId: string, name: string) => {
      if (!referralLinkMediaItemId || !name) {
        return undefined;
      }

      return changePartnerReferralLinkMediaItemNameMutation({
        variables: {
          input: {
            partnerId: user.id,
            referralLinkMediaItemId,
            name,
          },
        },
        onError: handleError,
        optimisticResponse: ({ input }, { IGNORE }) => {
          const currentReferralLinkMediaItem =
            client.readQuery<GetReferralLinkMediaItem>({
              query: GetReferralLinkMediaItemDocument,
              variables: {
                userId: input.partnerId,
                referralLinkMediaItemId: input.referralLinkMediaItemId,
              },
            })?.user.referralLinkMediaItem;

          if (!currentReferralLinkMediaItem) {
            return IGNORE;
          }

          return {
            __typename: 'Mutation',
            changePartnerReferralLinkMediaItemName: {
              __typename: 'ChangePartnerReferralLinkMediaItemNamePayload',
              referralLinkMediaItem: {
                ...currentReferralLinkMediaItem,
                name: input.name,
              },
            },
          };
        },
      });
    },
    [
      changePartnerReferralLinkMediaItemNameMutation,
      client,
      user.id,
      handleError,
    ]
  );

  return {
    changeReferralLinkMediaItemName,
    loading,
  };
};
