import { TariffTypeName } from 'types';
import { TariffNameData } from '../queries/fragments/generated/TariffNameData';

interface GetTariffsOptionsParams {
  currentTypeName: TariffTypeName;
  tariffs?: Array<TariffNameData>;
}

export const getTariffsOptions = ({
  currentTypeName,
  tariffs = [],
}: GetTariffsOptionsParams) =>
  tariffs
    .filter((tariff) => tariff.__typename === currentTypeName)
    .map((tariff) => ({
      id: tariff.id,
      label: tariff.name,
    }));
