import { FC, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/line/Pen.svg';
import { Icon, IconSize } from 'ui/Icon';
import { Formik } from 'formik';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useEditingIdsContext } from 'contexts/EditingIdsContext';
import { useChangeReferralLinkMediaItemName } from './hooks/useChangeReferralLinkMediaItemName';
import { MediaItemFormValues } from './types';
import { FieldName } from './const';
import { MediaItemNameForm } from './components/MediaItemNameForm';
import { validateMediaItemForm } from './validation';
import styles from './MediaItemName.module.scss';

interface MediaItemNameProps {
  referralLinkMediaItemId: string;
  initialName: string;
}

export const MediaItemName: FC<MediaItemNameProps> = ({
  referralLinkMediaItemId,
  initialName,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { changeReferralLinkMediaItemName, loading } =
    useChangeReferralLinkMediaItemName();
  const translationUserType = useTranslationUserType();
  const { updateEditingElementsIds } = useEditingIdsContext();

  const handleSubmit = async (values: MediaItemFormValues) => {
    if (initialName !== values[FieldName.Name]) {
      await changeReferralLinkMediaItemName(
        referralLinkMediaItemId,
        values[FieldName.Name]
      );
    }

    setIsEditing(false);
    updateEditingElementsIds(referralLinkMediaItemId, false);
  };

  return (
    <div>
      {isEditing ? (
        <Formik
          initialValues={{
            [FieldName.Name]: initialName,
          }}
          validate={(values) =>
            validateMediaItemForm(values, translationUserType)
          }
          onSubmit={handleSubmit}
        >
          <MediaItemNameForm
            referralLinkMediaItemId={referralLinkMediaItemId}
            loading={loading}
          />
        </Formik>
      ) : (
        <div className={styles.wrapper}>
          <span className={styles.name}>{initialName}</span>
          <button onClick={() => setIsEditing(true)} type="button">
            <Icon
              containerClassName={styles.icon}
              hasDefaultColor={false}
              iconComponent={EditIcon}
              size={IconSize.Small}
            />
          </button>
        </div>
      )}
    </div>
  );
};
