import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { TariffNameData } from '../fragments/generated/TariffNameData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetTariffsNameVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetTariffsName = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        tariffs: Array<
          | { __typename: 'CpaTariff'; id: string; name: string }
          | { __typename: 'RevShareTariff'; id: string; name: string }
        >;
      }
    | {
        __typename: 'Partner';
        id: string;
        tariffs: Array<
          | { __typename: 'CpaTariff'; id: string; name: string }
          | { __typename: 'RevShareTariff'; id: string; name: string }
        >;
      };
};

export const GetTariffsNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTariffsName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariffs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffNameData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariffs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffNameData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TariffNameData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetTariffsName__
 *
 * To run a query within a React component, call `useGetTariffsName` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsName({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTariffsName(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetTariffsName,
    GetTariffsNameVariables
  > &
    ({ variables: GetTariffsNameVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetTariffsName, GetTariffsNameVariables>(
    GetTariffsNameDocument,
    options
  );
}
export function useGetTariffsNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTariffsName,
    GetTariffsNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetTariffsName, GetTariffsNameVariables>(
    GetTariffsNameDocument,
    options
  );
}
export function useGetTariffsNameSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetTariffsName,
    GetTariffsNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetTariffsName,
    GetTariffsNameVariables
  >(GetTariffsNameDocument, options);
}
export type GetTariffsNameHookResult = ReturnType<typeof useGetTariffsName>;
export type GetTariffsNameLazyQueryHookResult = ReturnType<
  typeof useGetTariffsNameLazyQuery
>;
export type GetTariffsNameSuspenseQueryHookResult = ReturnType<
  typeof useGetTariffsNameSuspenseQuery
>;
export type GetTariffsNameQueryResult = Apollo.QueryResult<
  GetTariffsName,
  GetTariffsNameVariables
>;
