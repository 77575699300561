import { useTranslation } from 'react-i18next';
import { FC, ReactNode } from 'react';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { List, ListItem, ListSize } from 'ui/List';
import { Currency } from 'types';
import { DropdownPosition } from 'ui/Dropdown';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { ButtonTheme } from 'ui/Button';
import styles from './CurrencyDropdown.module.scss';

interface CurrencyDropdownProps {
  items: Array<ListItem<Currency>>;
  value: Currency;
  disabled?: boolean;
  icon?: ReactNode;
}

export const CurrencyDropdown: FC<CurrencyDropdownProps> = ({
  disabled,
  items,
  value,
  icon,
}) => {
  const { t } = useTranslation('common');

  return (
    <DropdownWithButton
      dropdownProps={{
        position: DropdownPosition.RightBottom,
        title: t('input.label.currency'),
      }}
      buttonContent={
        <div className={styles.innerButtonContent}>
          {icon && icon}
          {getCurrencyNameWithSymbol(value)}
        </div>
      }
      buttonProps={{
        disabled,
        theme: ButtonTheme.BlueLight,
      }}
    >
      <List items={items} size={ListSize.Small} highlightOnHover />
    </DropdownWithButton>
  );
};
