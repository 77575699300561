import * as SchemaTypes from '../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type CompanyPartnersFiltersData = {
  __typename: 'CompanyPartner';
  id: string;
  username: string;
};

export const CompanyPartnersFiltersData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyPartnersFiltersData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompanyPartner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
