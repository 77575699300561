import { UserTypeName } from 'types';
import { GetCompanyName } from '../queries/generated/GetCompanyName';
import { CompanyPartnersFiltersData } from '../queries/fragments/generated/CompanyPartnersFiltersData';

interface GetCompanyPartnersOptionsParams {
  userIdentities?: GetCompanyName;
  companyPartnersItems?: Array<CompanyPartnersFiltersData>;
}

export const getCompanyPartnersOptions = ({
  userIdentities,
  companyPartnersItems,
}: GetCompanyPartnersOptionsParams) => [
  ...(userIdentities?.user.__typename === UserTypeName.Company
    ? [
        {
          id: userIdentities.user.id,
          label: userIdentities.user.name,
        },
      ]
    : []),
  ...(companyPartnersItems?.map((companyPartnersItem) => ({
    id: companyPartnersItem.id,
    label: companyPartnersItem.username,
  })) ?? []),
];
