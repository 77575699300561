import { ListItem } from 'ui/List';
import { Currency } from 'types';
import { Language } from 'types/generated/gql';
import { FlagIcon } from 'ui/FlagIcon';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';

interface GetListItemsByCurrenciesParams {
  onClick: (value?: Currency) => void;
  language: Language;
  availableCurrencies?: Array<Currency | null>;
}

export const getListItemsByCurrencies = ({
  onClick,
  availableCurrencies,
  language,
}: GetListItemsByCurrenciesParams): Array<ListItem<Currency>> => {
  if (!availableCurrencies?.length) {
    return [];
  }

  return availableCurrencies
    .filter((currency): currency is Currency => currency !== null)
    .map((currency) => ({
      label: getCurrencyDisplayName({ currencyCode: currency, language }),
      labelRight: getCurrencyNameWithSymbol(currency),
      leftElement: <FlagIcon code={currency} />,
      value: currency,
      onClick,
    }));
};
