import { Currency, UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { useSwitchPartnerNextRewardCurrencyMutation } from './useSwitchPartnerNextRewardCurrencyMutation';
import { useSwitchCompanyNextRewardCurrencyMutation } from './useSwitchCompanyNextRewardCurrencyMutation';

interface UseSwitchNextRewardCurrencyParams {
  onCompleted: (currency: Currency) => void;
}

export const useSwitchNextRewardCurrency = ({
  onCompleted,
}: UseSwitchNextRewardCurrencyParams) => {
  const { t } = useTranslation('common');

  const { userTypeName, loading: userTypeNameLoading } =
    useGetUserTypeNameQuery({
      onError: () => {
        toast.error(t('errors.commonError'));
      },
    });

  const partnerResult = useSwitchPartnerNextRewardCurrencyMutation(onCompleted);
  const companyResult = useSwitchCompanyNextRewardCurrencyMutation(onCompleted);

  const result =
    userTypeName === UserTypeName.Partner ? partnerResult : companyResult;

  return { ...result, loading: userTypeNameLoading || result.loading };
};
