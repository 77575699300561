import { useCallback, useState } from 'react';
import { format, isAfter, isValid, parse } from 'date-fns';
import { DateFormat } from 'utils/formatDate';

const dateRegex = /[^0-9]/g;

export const useValidatedDateInput = (initialValue: string) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const validateAndFormatInput = useCallback((value: string | null) => {
    if (!value) {
      setInputValue(' ');

      return;
    }

    const today = new Date();
    const cleanedValue = value.replace(dateRegex, '');

    if (!cleanedValue) {
      setInputValue(' ');

      return;
    }

    let formattedValue = cleanedValue.slice(0, 2);

    if (cleanedValue.length >= 3) {
      formattedValue += `.${cleanedValue.slice(2, 4)}`;
    }

    if (cleanedValue.length >= 5) {
      formattedValue += `.${cleanedValue.slice(4, 8)}`;
    }

    if (formattedValue.length < 10) {
      setInputValue(formattedValue);

      return;
    }

    const parsedDate = parse(formattedValue, DateFormat.DefaultDate, today);

    setInputValue(
      isValid(parsedDate) && !isAfter(parsedDate, today)
        ? format(parsedDate, DateFormat.DefaultDate)
        : format(today, DateFormat.DefaultDate)
    );
  }, []);

  return {
    inputValue,
    validateAndFormatInput,
  };
};
