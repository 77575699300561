import { Currency } from 'types';
import { FlagIcon } from 'ui/FlagIcon';
import { DropdownValue } from '../types';

export const getDropdownStateByCurrency = (
  currencyCode: Currency
): DropdownValue => ({
  value: currencyCode,
  label: currencyCode,
  icon: <FlagIcon code={currencyCode} />,
});
