import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NonNullableDateRange } from 'types';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useGetCpaStatisticDetailedQuery } from './hooks/useGetCpaStatisticDetailedQuery';
import { useCpaStatisticDetailedColumns } from './hooks/useCpaStatisticDetailedColumns';
import { extractDetailedStatisticItems } from './helpers';
import { CpaReferralLinkStatisticItemData } from './query/fragments/generated/CpaReferralLinkStatisticItemData';
import { useGenerateCpaStatisticDetailedSummaryRow } from './hooks/useGenerateCpaStatisticDetailedSummaryRow';
import { CpaStatisticDetailedTableSearch } from './components/CpaStatisticDetailedTableSearch';

interface CpaStatisticDetailedTableProps {
  cpaMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const CpaStatisticDetailedTable: FC<CpaStatisticDetailedTableProps> = ({
  cpaMediaItemId,
  nonNullableDateRange,
}) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const { t } = useTranslation('statistic');

  const translationUserType = useTranslationUserType();

  const {
    cpaStatisticDetailed,
    pageInfo,
    fetchMore,
    loading: itemsQueryLoading,
    error: itemsQueryError,
    isSliced,
  } = useGetCpaStatisticDetailedQuery({
    cpaMediaItemId,
    nonNullableDateRange,
    searchQuery,
  });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateCpaStatisticDetailedSummaryRow({
    cpaMediaItemId,
    nonNullableDateRange,
    searchQuery,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = useCpaStatisticDetailedColumns();

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const tableData: Array<CpaReferralLinkStatisticItemData> | undefined =
    extractDetailedStatisticItems(
      cpaStatisticDetailed?.cpaStatistic.statisticItems
    ) ?? [];

  return (
    <Table
      columns={columns}
      data={tableData}
      emptyText={t(`${translationUserType}.content.placeholder.noData`)}
      summaryRow={summaryRowData}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
      error={error}
      hasSearchParams={!!searchQuery}
      emptySearchStateTitle={t(
        `${translationUserType}.content.placeholder.referrals.referralsNoDataHeader`
      )}
      emptySearchStateDescription={t(
        `${translationUserType}.content.placeholder.referrals.referralsNoDataSubheader`
      )}
      errorStateTitle={t(
        'placeholder.loadingError.loadingErrorHeaderReferrals'
      )}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheaderReferrals'
      )}
      searchComponent={
        <CpaStatisticDetailedTableSearch
          initialValues={{ search: searchQuery }}
        />
      }
    />
  );
};
