import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/formatDate';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { CopyLink } from 'ui/CopyLink';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { ReferralLinkMediaItemData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { getMediaItemOwnerToShow } from './helpers';
import { MediaItemName } from './components/MediaItemName';

interface ReferralLinkMediaDetailsProps {
  referralLinkMediaItem: ReferralLinkMediaItemData;
}

export const ReferralLinkMediaDetails: FC<ReferralLinkMediaDetailsProps> = ({
  referralLinkMediaItem,
}) => {
  const { name, id, link, createdAt } = referralLinkMediaItem;
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const referralLinkMediaItemOwner = getMediaItemOwnerToShow(
    referralLinkMediaItem.owner
  );

  const keyValueGridRows: Array<KeyValueGridRow | Falsy> = [
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.mediaName`
      ),
      value: <MediaItemName referralLinkMediaItemId={id} initialName={name} />,
    },
    referralLinkMediaItemOwner && {
      title: t('media:company.content.drawer.details.aboutMedia.label.owner'),
      value: referralLinkMediaItemOwner,
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.mediaId`
      ),
      value: id,
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.link`
      ),
      value: (
        <CopyLink target="_blank" to={link} tooltipText={t('linkCopied')}>
          {link}
        </CopyLink>
      ),
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.createdDate`
      ),
      value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutHours),
    },
  ];

  return <KeyValueGrid data={keyValueGridRows} shouldStackOnMd />;
};
