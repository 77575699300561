import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Icon, IconSize } from 'ui/Icon';
import { Button, ButtonSize } from 'ui/Button';
import { ReactComponent as Update } from 'assets/icons/line/Refresh.svg';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import styles from './NestedTableRowFooter.module.scss';

interface NestedTableRowFooterProps {
  isLoading?: boolean;
  hasMoreData?: boolean;
  moreDataButtonText?: string;
  loadMore?: () => void;
  error?: ApolloError;
}

export const NestedTableRowFooter: FC<NestedTableRowFooterProps> = ({
  isLoading,
  hasMoreData,
  moreDataButtonText,
  loadMore,
  error,
}) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.statusContainer}>
          <span className={styles.text}>{t('progress.loadingError')}</span>
          {loadMore && (
            <Button
              className={styles.updateButton}
              linkStyled
              iconConfig={{
                iconComponent: Update,
                before: true,
              }}
              onClick={() => {
                loadMore?.();
              }}
              size={ButtonSize.Medium}
            >
              {t('progress.refresh')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.statusContainer}>
          <Icon
            iconClassName={styles.loadingIcon}
            iconComponent={LoadingIcon}
            hasDefaultColor={false}
            size={IconSize.Small}
            spin
          />
          <span className={styles.text}>{t('progress.loading')}</span>
        </div>
      </div>
    );
  }

  if (hasMoreData) {
    return (
      <div className={styles.container}>
        <div className={styles.statusContainer}>
          <Button linkStyled onClick={loadMore} size={ButtonSize.Small}>
            {moreDataButtonText}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.statusContainer}>
        <span className={styles.text}>{t('progress.allDataLoaded')}</span>
      </div>
    </div>
  );
};
