import { FC } from 'react';
import { Currency, NonNullableDateRange } from 'types';
import { StatisticType } from '../../../../const';
import { CpaStatisticTable } from '../CpaStatisticTable';
import { RevShareStatisticTable } from '../RevShareStatisticTable';

interface StatisticTableByTypeProps {
  statisticType: StatisticType;
  nonNullableDateRange: NonNullableDateRange;
  updateCurrency: (currency: Currency) => void;
  currency: Currency;
}

export const StatisticTableByType: FC<StatisticTableByTypeProps> = ({
  nonNullableDateRange,
  statisticType,
  currency,
  updateCurrency,
}) => {
  const map: Record<StatisticType, JSX.Element> = {
    [StatisticType.Cpa]: (
      <CpaStatisticTable nonNullableDateRange={nonNullableDateRange} />
    ),
    [StatisticType.RevShare]: (
      <RevShareStatisticTable
        nonNullableDateRange={nonNullableDateRange}
        currency={currency}
        updateCurrency={updateCurrency}
      />
    ),
  };

  return map[statisticType];
};
