import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetAvailableCurrenciesForStatisticVariables = SchemaTypes.Exact<{
  [key: string]: never;
}>;

export type GetAvailableCurrenciesForStatistic = {
  __typename: 'Query';
  availableCurrenciesForStatistic: Array<CommonType.Currency>;
};

export const GetAvailableCurrenciesForStatisticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableCurrenciesForStatistic' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableCurrenciesForStatistic' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAvailableCurrenciesForStatistic__
 *
 * To run a query within a React component, call `useGetAvailableCurrenciesForStatistic` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCurrenciesForStatistic` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCurrenciesForStatistic({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableCurrenciesForStatistic(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >(GetAvailableCurrenciesForStatisticDocument, options);
}
export function useGetAvailableCurrenciesForStatisticLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >(GetAvailableCurrenciesForStatisticDocument, options);
}
export function useGetAvailableCurrenciesForStatisticSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetAvailableCurrenciesForStatistic,
    GetAvailableCurrenciesForStatisticVariables
  >(GetAvailableCurrenciesForStatisticDocument, options);
}
export type GetAvailableCurrenciesForStatisticHookResult = ReturnType<
  typeof useGetAvailableCurrenciesForStatistic
>;
export type GetAvailableCurrenciesForStatisticLazyQueryHookResult = ReturnType<
  typeof useGetAvailableCurrenciesForStatisticLazyQuery
>;
export type GetAvailableCurrenciesForStatisticSuspenseQueryHookResult =
  ReturnType<typeof useGetAvailableCurrenciesForStatisticSuspenseQuery>;
export type GetAvailableCurrenciesForStatisticQueryResult = Apollo.QueryResult<
  GetAvailableCurrenciesForStatistic,
  GetAvailableCurrenciesForStatisticVariables
>;
