import { FC } from 'react';
import { CopyText } from 'ui/CopyText';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { Icon } from 'ui/Icon';
import { useTranslation } from 'react-i18next';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import styles from './TwoFactorGeneration.module.scss';
import { useCreateTwoFactor } from './hooks/useCreateTwoFactor';

export const TwoFactorGeneration: FC = () => {
  const { t } = useTranslation('auth');
  const { isLoading, qrCodeBase64, secretCode } = useCreateTwoFactor();
  const translationUserType = useTranslationUserType();

  if (isLoading) {
    return (
      <div className={styles.twoFactoWrapper}>
        <Icon iconComponent={LoadingIcon} spin />
      </div>
    );
  }

  return (
    <div className={styles.twoFactoWrapper}>
      {qrCodeBase64 && (
        <div className={styles.imageWrapper}>
          <img alt="qrCode" src={qrCodeBase64} />
        </div>
      )}
      {secretCode && (
        <div className={styles.secretCodeWrapper}>
          <CopyText
            tooltipText={t(
              `signup.${translationUserType}.action.2FAConnection.hint.copyLink`
            )}
          >
            {secretCode}
          </CopyText>
        </div>
      )}
    </div>
  );
};
