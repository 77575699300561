import { useAuth } from 'components/auth';
import {
  Currency,
  FilterType,
  NonNullableDateRange,
  UserTypeName,
} from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { useGetCompanyRevShareStatisticTotal } from '../queries/generated/GetCompanyRevShareStatisticTotal';

const { DateScalar } = DateFormat;

interface UseGetCompanyRevShareStatisticTotalQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  currency: Currency;
  searchQuery?: string;
}

export const useGetCompanyRevShareStatisticTotalQuery = ({
  nonNullableDateRange,
  filters,
  currency,
  searchQuery,
}: UseGetCompanyRevShareStatisticTotalQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const { data, loading, error } = useGetCompanyRevShareStatisticTotal({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      userId: user.id,
      filters,
      currency,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    cacheFirstOnPop: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Company
      ? data.user.revShareStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
