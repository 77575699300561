import { FC, ReactNode } from 'react';
import { NonNullableDateRange } from 'types';
import { CpaStatisticDetailedTable } from '../CpaStatisticDetailedTable';
import { RevShareStatisticDetailedTable } from '../RevShareStatisticDetailedTable';
import { StatisticType } from '../../../../const';

interface StatisticDetailedByTypeProps {
  type: StatisticType;
  referralLinkMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const StatisticDetailedByType: FC<StatisticDetailedByTypeProps> = ({
  referralLinkMediaItemId,
  type,
  nonNullableDateRange,
}) => {
  const mapTypeToNode: Record<StatisticType, ReactNode> = {
    [StatisticType.Cpa]: (
      <CpaStatisticDetailedTable
        cpaMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
      />
    ),
    [StatisticType.RevShare]: (
      <RevShareStatisticDetailedTable
        revShareMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
      />
    ),
  };

  return mapTypeToNode[type];
};
