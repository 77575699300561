import { toast } from 'ui/toast';
import { t } from 'i18n';

export const copyToClipboard = (text: string) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      const textarea = document.createElement('textarea');

      textarea.textContent = text;
      textarea.style.position = 'fixed';

      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  } catch (error) {
    toast.error(t('errors.commonError'));
  }
};
